import './styles.scss'

import React, { useState } from 'react'

const Loader = (WrappedComponent) => {
  return (props) => {
    const [isLoading, setIsLoading] = useState(false)

    const setLoader = (loadingState) => {
      setIsLoading(loadingState)
    }

    return (
      <>
        {isLoading && (
          <div className="loader">
            <div className="spinner"></div>
          </div>
        )}
        <WrappedComponent {...props} setLoader={setLoader} />
      </>
    )
  }
}

export default Loader
