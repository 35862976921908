// src/components/ProtectedRoute.js

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { getRoleConfig } from 'Utils/Constants/RolesConfig'
import { getLocal } from 'Utils/LocalStorageHandler'

const ProtectedRoute = ({ allowedRoles }) => {
  const isLoggedIn = getLocal('isLoggedIn')
  const userRole = getLocal('userRole')

  if (!isLoggedIn) {
    return <Navigate to="/login" />
  }

  const { allowedRoutes } = getRoleConfig(userRole)

  const isAllowed =
    allowedRoles.includes(userRole) &&
    allowedRoutes.includes(window.location.pathname)

  return isAllowed ? <Outlet /> : <Navigate to="/not-authorized" />
}

export default ProtectedRoute
