import './styles.scss'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTE_STRINGS } from 'Utils/Constants'

import AddOptionsDialogModal from '../AddOptionsDialogModal'
import CategoryDialogModal from '../CategoryDialogModal'
import ChangeItemTypeDialogModal from '../ChangeItemTypeDialogModal'
import SalesTaxDialogModal from '../SalesTaxDialogModal'

const CreateItem = () => {
  const navigate = useNavigate()
  const [itemTypeOpen, setItemTypeOpen] = useState(false)
  const [categoryOpen, setCategoryOpen] = useState(false)
  const [optionOpen, setOptionOpen] = useState(false)
  const [addonOpen, setAddonOpen] = useState(false)
  const [mixerOpen, setMixerOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const [categoryDialogModalOpen, setCategoryDialogModalOpen] = useState(false)
  const [salesTaxDialogModalOpen, setSalesTaxDialogModalOpen] = useState(false)
  const [addOptionsDialogModalOpen, setAddOptionsDialogModalOpen] =
    useState(false)
  const [changeItemTypeDialogModalOpen, setChangeItemTypeDialogModalOpen] =
    useState(false)
  const handleCategoryDialogModalClose = () => {
    setCategoryDialogModalOpen(true)
  }

  const handleSalesTaxDialogModalClose = () => {
    setSalesTaxDialogModalOpen(false)
  }

  const handleAddOptionsDialogModalClose = () => {
    setAddOptionsDialogModalOpen(true)
  }

  const handleChangeItemTypeDialogModalClose = () => {
    setChangeItemTypeDialogModalOpen(false)
  }

  const handleModalOpen = (setModalOpen) => {
    setModalOpen(true)
  }

  const handleModalClose = (setModalOpen) => {
    setModalOpen(false)
  }

  const handleEditOpen = (setModalOpen) => {
    setModalOpen(false)
  }

  const handleDialogOpen = (setModalOpen) => {
    setModalOpen(false)
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  return (
    <Container className="create-item-container">
      <Box className="top-bar">
        <IconButton
          className="back-button"
          onClick={() => navigate(ROUTE_STRINGS.item)}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Box className="top-bar-right">
          <Button variant="contained" className="top-bar-button">
            <StarBorderIcon className="star-icon" />
            <span>Set as Featured Item</span>
          </Button>
          <Button variant="contained" className="top-bar-button">
            <span>Save</span>
          </Button>
        </Box>
      </Box>

      <Container className="item-form-container">
        <Box className="details-section">
          <Typography variant="h6" className="section-title">
            Details
          </Typography>
          <Box className="details-grid">
            <Box className="detail-item">
              <Box className="item-type-container">
                <Box className="item-type-content">
                  <Typography variant="body1" className="item-type-label">
                    Item type
                  </Typography>
                  <Typography variant="body1" className="item-type-value">
                    Beverage
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  className="change-button"
                  onClick={handleModalOpen}
                >
                  Change
                </Typography>
              </Box>
              <Box className="item-type-container m-b-0">
                <TextField
                  label="Name (required)"
                  variant="standard"
                  className="span-two input-field"
                />
              </Box>
            </Box>
            <Box className="span-one upload-image-container p-0">
              <Box className="upload-image">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    className="upload-button"
                  >
                    Upload an image*
                  </Button>
                </label>
                {selectedFile && (
                  <Typography variant="body2" className="file-name">
                    {selectedFile.name}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box className="item-type-container m-b-0">
              <TextField
                label="Item price (required)"
                variant="standard"
                className="input-field span-two"
              />
            </Box>
            <Box className="span-one upload-image-container">
              <TextField
                label="On Sale Price"
                variant="standard"
                className="input-field half-width"
              />
            </Box>

            <Box className="span-two upload-image-container">
              <TextField
                label="Description"
                variant="standard"
                fullWidth
                multiline
                rows={4}
                className="input-field span-two "
              />
            </Box>

            <Box className="item-type-container">
              <TextField
                label="SKU"
                variant="standard"
                className="input-field span-two"
              />
            </Box>

            <Box className="item-type-container">
              <Box className="item-type-content">
                <Typography variant="body1" className="item-type-label">
                  Taxes
                </Typography>
                <Typography variant="body1" className="item-type-value">
                  GST & Surcharge
                </Typography>
                {/* <Select label="Taxes">
                  <MenuItem value="GST & Surcharge">GST & Surcharge</MenuItem>
                </Select> */}
              </Box>
              <IconButton
                className="dropdown-button"
                onClick={() => navigate(ROUTE_STRINGS.item)}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box className="categorization-section">
          <Typography variant="h6" className="section-title">
            <span>Categorization</span>
          </Typography>
          <Box className="button-section category-button-container">
            <Box className="left-button">
              <FolderOpenIcon className="folder-open-icon" />
              <Typography variant="body1" className="category-text">
                Categories
              </Typography>
            </Box>
            <Box className="right-button" onClick={handleDialogOpen}>
              <Typography variant="body1" className="select-text">
                Select
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="variations-section">
          <Typography variant="h6" className="section-title">
            <span>Variations</span>
          </Typography>

          {!optionOpen ? (
            <Box className="options-section">
              <Typography variant="h6" className="section-sub-title">
                Options
              </Typography>
              <Typography className="section-sub-content">
                Add a custom set of options to an item to create variations. For
                example, a size option set can create the variations: small,
                medium, and large or glass, bottle, jug.
              </Typography>
              <Button
                variant="standard"
                fullWidth
                className="modal-button"
                onClick={() => setOptionOpen(true)}
              >
                <span>Add Options</span>
              </Button>
            </Box>
          ) : (
            <Box>
              <TableContainer
                component={Paper}
                className="custom-table-container"
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Option Name</TableCell>
                      <TableCell>Values</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Size</TableCell>
                      <TableCell>Small, Medium, Large</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Container</TableCell>
                      <TableCell>Glass, Bottle, Jug</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {!addonOpen ? (
            <Box className="addon-section">
              <Typography variant="h6" className="section-sub-title">
                ADD-ONS
              </Typography>
              <Typography className="section-sub-content">
                Create a list of ADD-ONS or Extra. For example, double shot,
                triple shot.
              </Typography>
              <Button
                variant="standard"
                fullWidth
                className="modal-button"
                onClick={() => setAddonOpen(true)}
              >
                <span>Create ADD-ONS</span>
              </Button>
            </Box>
          ) : (
            <Box className="mixers-addons-container">
              <Box className="section-header">
                <Typography variant="h6" className="section-title">
                  ADD-ONS
                </Typography>

                <Typography variant="body2" className="section-description">
                  Create a list of ADD-ONS or Extra. For example, double shot,
                  triple shot.
                </Typography>

                <Button className="edit-button" onClick={handleEditOpen}>
                  Edit
                </Button>
              </Box>

              <Box className="mixers-addons-section">
                <Box className="mixers-addons-row">
                  <Box>
                    <Typography variant="h6" className="mixers-addons-title">
                      ADD-ONS
                    </Typography>
                    <Typography variant="body2" className="mixers-addons-item">
                      Single Shot, Double Shot, Triple Shot, Quadruple Shot,
                      Vibes
                    </Typography>
                  </Box>
                  <IconButton className="dots-icon">
                    <MoreHorizIcon />
                  </IconButton>
                </Box>
                <Box className="mixers-addons-row">
                  <Box>
                    <Typography variant="h6" className="mixers-addons-title">
                      Some other name
                    </Typography>
                    <Typography variant="body2" className="mixers-addons-item">
                      Single Shot, Double Shot, Triple Shot, Quadruple Shot,
                      Vibes
                    </Typography>
                  </Box>

                  <IconButton className="dots-icon">
                    <MoreHorizIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          )}

          {!mixerOpen ? (
            <Box className="mixer-section">
              <Typography variant="h6" className="section-sub-title">
                Mixers
              </Typography>
              <Typography className="section-sub-content">
                Add a custom set of mixers to an item or apply one you've
                already created. For example, customers can add coke, coke zero,
                etc., to their beverage of choice.
              </Typography>
              <Button
                variant="standard"
                fullWidth
                className="modal-button"
                onClick={() => setMixerOpen(true)}
              >
                <span>Add Mixers</span>
              </Button>
            </Box>
          ) : (
            <Box className="mixers-addons-container">
              <Box className="section-header">
                <Typography variant="h6" className="section-title">
                  Mixers
                </Typography>

                <Typography variant="body2" className="section-description">
                  Add a custom set of mixers to an item or apply one you've
                  already created. For example, customers can add coke, coke
                  zero etc to their beverage of choice.
                </Typography>

                <Button className="edit-button" onClick={handleEditOpen}>
                  Edit
                </Button>
              </Box>

              <Box className="mixers-addons-section">
                <Box className="mixers-addons-row">
                  <Box>
                    <Typography variant="h6" className="mixers-addons-title">
                      ADD-ONS
                    </Typography>
                    <Typography variant="body2" className="mixers-addons-item">
                      Single Shot, Double Shot, Triple Shot, Quadruple Shot,
                      Vibes
                    </Typography>
                  </Box>
                  <IconButton className="dots-icon">
                    <MoreHorizIcon />
                  </IconButton>
                </Box>
                <Box className="mixers-addons-row">
                  <Box>
                    <Typography variant="h6" className="mixers-addons-title">
                      Some other name
                    </Typography>
                    <Typography variant="body2" className="mixers-addons-item">
                      Single Shot, Double Shot, Triple Shot, Quadruple Shot,
                      Vibes
                    </Typography>
                  </Box>

                  <IconButton className="dots-icon">
                    <MoreHorizIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Container>

      <CategoryDialogModal
        open={categoryDialogModalOpen}
        onClose={handleCategoryDialogModalClose}
      />
      <SalesTaxDialogModal
        open={salesTaxDialogModalOpen}
        onClose={handleSalesTaxDialogModalClose}
      />
      <AddOptionsDialogModal
        open={addOptionsDialogModalOpen}
        onClose={handleAddOptionsDialogModalClose}
      />
      <ChangeItemTypeDialogModal
        open={changeItemTypeDialogModalOpen}
        onClose={handleChangeItemTypeDialogModalClose}
      />

      <Modal
        open={itemTypeOpen}
        onClose={() => handleModalClose(setItemTypeOpen)}
      >
        <Box className="modal-box">
          <Typography variant="h6">Select Item Type</Typography>
          <Select variant="standard" fullWidth>
            <MenuItem value="Beverage">Beverage</MenuItem>
            <MenuItem value="Food">Food</MenuItem>
          </Select>
        </Box>
      </Modal>
    </Container>
  )
}

export default CreateItem
