import './styles.scss'

import React, { useState } from 'react'

const ResetPassword = ({ handleView }) => {
  const [mailSent, setMailSent] = useState(false)
  const [email, setEmail] = useState('test@abc.com')

  return (
    <div className="component-container">
      {!mailSent && (
        <div className="reset-password-form">
          <div className="text-wrapper-6">Reset your password</div>
          <div className="head1">
            Enter the email address you used to register with.
          </div>
          <div className="frame">
            <div className="EMAIL-wrapper">
              <div className="text-wrapper-4">
                <input className="email" type="email" placeholder="Email" />
              </div>
            </div>
          </div>
          <div className="text-wrapper-5">Forgot or lost your email?</div>
          <div className="dual-button">
            <div className="overlap-group">
              <button
                className="text-wrapper-3 grey-color"
                onClick={() => handleView('signin')}
              >
                Back to login
              </button>
            </div>
            <div className="overlap-group ml-auto">
              <button
                className="text-wrapper-3"
                onClick={() => setMailSent(true)}
              >
                Send instructions
              </button>
            </div>
          </div>
        </div>
      )}

      {mailSent && (
        <div className="email-sent">
          <div className="">
            <div className="text-wrapper-6">Check your inbox</div>
            <div className="head2">
              A link was sent to {email} to reset your password. If you don’t
              see it in your inbox, remember to check your spam folder.
            </div>
          </div>
          <div className="text-wrapper-5">Still don’t see the email?</div>
          <div className="dual-button">
            <div className="overlap-group">
              <button
                className="text-wrapper-3 grey-color"
                onClick={() => handleView('signin')}
              >
                Back to login
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ResetPassword
