import { REG_EX } from './Constants'

const {
  PHONE_REG_EX,
  EMAIL_REG_EX,
  PASSWORD_REG_EX,
  NAME_REG_EX,
  NUMBER_DOTS_REG_EX,
  LANGUAGE_REG_EX,
  USER_NAME_REG_EX,
  ONLY_NUMBER_REG_EX,
  COLOR_PATTERN,
  PHONE_SPECIAL_CHAR,
  URL_REG_EX,
} = REG_EX

const phoneNumberValidation = (value) => {
  return PHONE_REG_EX.test(value.trim())
}

const emailValidation = (value) => {
  return EMAIL_REG_EX.test(value.trim())
}

const phoneValidation = (value) => {
  return PHONE_REG_EX.test(value.trim())
}

const passwordValidation = (value) => {
  return PASSWORD_REG_EX.test(value.trim())
}

const nameValidation = (value) => {
  return NAME_REG_EX.test(value.trim())
}

const userNameValidation = (value) => {
  return USER_NAME_REG_EX.test(value.trim())
}

const numberAndDotsValidation = (value) => {
  return NUMBER_DOTS_REG_EX.test(value.trim())
}

const checkEmptyField = (...values) => {
  return values.every((value) => value === '')
}

const checkAllInputFieldsNotEmpty = (...values) => {
  return values.every((value) => value !== '')
}

const checkEmailValidity = (checkEmail) => {
  return EMAIL_REG_EX.test(checkEmail)
}

const checkEmptySomeInputField = (...values) => {
  return values.some((value) => value === '')
}

const isInputFieldEmpty = (value) => {
  return value.trim() === ''
}

const checkEmptyFieldNull = (...values) => {
  return values.every((value) => value === null)
}

const languageValidation = (value) => {
  return LANGUAGE_REG_EX.test(value.trim())
}

const numberValidation = (value) => {
  return ONLY_NUMBER_REG_EX.test(value.trim())
}

const colorValidation = (value) => {
  return COLOR_PATTERN.test(value.trim())
}

const phoneCharValidation = (value) => {
  return PHONE_SPECIAL_CHAR.test(value.trim())
}

const urlValidation = (value) => {
  return URL_REG_EX.test(value.trim())
}

export {
  phoneNumberValidation,
  emailValidation,
  phoneValidation,
  passwordValidation,
  nameValidation,
  userNameValidation,
  numberAndDotsValidation,
  checkEmptyField,
  checkAllInputFieldsNotEmpty,
  checkEmailValidity,
  checkEmptySomeInputField,
  isInputFieldEmpty,
  checkEmptyFieldNull,
  languageValidation,
  numberValidation,
  colorValidation,
  phoneCharValidation,
  urlValidation,
}
