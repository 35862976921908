import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import React from 'react'

const QRCodeModal = ({ base64STR, onClose }) => {
  const handleClose = () => {
    onClose()
  }

  const downloadQRCode = () => {
    const link = document.createElement('a')

    link.href = `data:image/png;base64,${base64STR}`
    link.download = 'QRCode.png'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Modal open={true} onClose={handleClose}>
      <Box className="qr-code-modal-container">
        <div className="qr-code-modal-header">
          <Typography variant="h6">QR Code</Typography>
          <IconButton onClick={handleClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </div>

        <div className="qr-code-content">
          <embed
            src={`data:image/png;base64,${base64STR}`}
            width="250"
            height="250"
            alt="QR Code"
            className="qr-code-img"
          />
        </div>

        <div className="qr-code-modal-footer">
          <Button className="download-btn" onClick={downloadQRCode}>
            <DownloadIcon style={{ marginRight: '8px' }} />
            Download
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default QRCodeModal
