import './styles.scss'

import { editIcon } from 'Assets/Images'
import Loader from 'components/Loader'
import React, { useState } from 'react'

const VenueDetails = ({ setLoader }) => {
  const [venueDetails, setVenueDetails] = useState([
    { venue: 'venue_name' },
    { Street: 'street_name' },
    { city: 'city_name' },
    { state: 'state_name' },
    { postCode: 'post_code' },
    { country: 'Country' },
    { venueType: 'Venue Type' },
  ])

  return (
    <div className="venue-details-container">
      <div className="container">
        <div className="header">
          <div className="title">Venue Details</div>
          <div className="icon-container">
            <img src={editIcon} alt="edit" className="edit-button" />
          </div>
        </div>

        <ul className="venue-details-list">
          {venueDetails.map((detail, index) => {
            const label = Object.keys(detail)[0]
            const value = detail[label]

            return (
              <li key={index} className="venue-details-item">
                <span className="label">
                  {label.charAt(0).toUpperCase() + label.slice(1)}{' '}
                </span>
                <span className="value">{value}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Loader(VenueDetails)
