import './styles.scss'

import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core'
import ConfirmationModal from 'components/ConfirmationModal'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { STORAGE_KEYS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

import CreateUserModal from './Components/CreateUserModal'

const Users = ({ setLoader }) => {
  const [allActiveRoles, setAllActiveRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [roleUsers, setRoleUsers] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [currentRowData, setCurrentRowData] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isConfirmationOpen, setConfirmationOpen] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState(null)

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (selectedRole) {
      getRolesListByRoleId(selectedRole)
    }
  }, [selectedRole])

  const init = async () => {
    setLoader(true)
    // setShowTableMessage(true)
    try {
      const response = await apiAdapter.getAllActiveRoles()

      if (response.data.status) {
        const {
          data: { data: rolesData },
        } = response

        setAllActiveRoles(rolesData)
        if (rolesData.length > 0) {
          setSelectedRole(rolesData[0].userRoleId)
        }
      }
    } catch (error) {
      console.error('Error occurred while fetching users:', error)
    } finally {
      setLoader(false)
      // setShowTableMessage(false)
    }
  }

  const getRolesListByRoleId = async (roleId) => {
    setLoader(true)
    // setShowTableMessage(true)
    console.log('selectedRole', roleId)

    try {
      const response = await apiAdapter.getUsersByRoleId({ roleId })

      if (response.data.status) {
        const {
          data: { data: rolesList },
        } = response

        setRoleUsers(rolesList)
      }
    } catch (error) {
      console.error('Error occurred while fetching users:', error)
      if (error.response.status === 400) {
        setRoleUsers([])
        swal('USERS NOT FOUND', error.response.data.message, 'info')
      }
    } finally {
      setLoader(false)
      // setShowTableMessage(false)
    }
  }

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleDelete = (userId) => {
    setUserIdToDelete(userId)
    setConfirmationOpen(true)
  }

  const handleDeleteConfirm = async () => {
    setConfirmationOpen(false)
    setLoader(true)

    try {
      const response = await apiAdapter.deleteUserById({
        userId: userIdToDelete,
      })

      if (response?.status) {
        swal('Success', 'User deleted successfully!', 'success')
        getRolesListByRoleId(selectedRole)
      }
    } catch (error) {
      console.error('Error occurred while deleting user:', error)
      swal('Error', 'Failed to delete user', 'error')
    } finally {
      setLoader(false)
    }
  }

  const handleOpenModal = (rowData = null) => {
    if (rowData) {
      setCurrentRowData(rowData)
    }
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setCurrentRowData(null)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Filter the users based on search term
  const filteredUsers = roleUsers.filter((user) =>
    `${user.firstName} ${user.lastName} ${user.userEmail}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  )

  return (
    <Container className="table-container">
      <div className="actions-section">
        <div className="left-section">
          <FormControl variant="outlined" className="users-formControl">
            <InputLabel id="users-dropdown-label">Select Roles:</InputLabel>
            <Select
              labelId="users-dropdown-label"
              id="users-dropdown"
              value={selectedRole}
              onChange={handleRoleChange}
              label="Get By"
            >
              {allActiveRoles.map((role) => (
                <MenuItem key={role.userRoleId} value={role.userRoleId}>
                  {role.userRole}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Search Field */}
          <TextField
            variant="outlined"
            label="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-field"
          />
        </div>
        <div className="right-section">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenModal()}
          >
            Create User
          </Button>
        </div>
      </div>

      <div className="table-section">
        {/* console error is due to data issue, fix is below */}
        {/* https://github.com/mbrn/material-table/issues/1074 */}
        <TableContainer component={Paper}>
          <Table className="table" aria-label="users table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell">First Name</TableCell>
                <TableCell className="table-cell">Last Name</TableCell>
                <TableCell className="table-cell">Email</TableCell>
                <TableCell className="table-cell">Mobile No</TableCell>
                <TableCell className="table-cell">Status</TableCell>
                <TableCell className="table-cell">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <TableRow key={user.userId} className="users-table-row">
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.userEmail}</TableCell>
                    <TableCell>{user.mobileNo}</TableCell>
                    <TableCell>{user.userStatus}</TableCell>
                    <TableCell>
                      {user.userStatus === 'ACTIVE' && (
                        <Button
                          variant="contained"
                          className="users-delete-button"
                          onClick={() => handleDelete(user.userId)}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          {/* Conditionally show pagination if there are more than 10 users */}
          {filteredUsers.length > 10 && (
            <TablePagination
              component="div"
              count={filteredUsers.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>

        <CreateUserModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          initialData={currentRowData}
          allActiveRoles={allActiveRoles}
        />

        <ConfirmationModal
          isOpen={isConfirmationOpen}
          onClose={() => setConfirmationOpen(false)}
          onConfirm={handleDeleteConfirm}
          title="Confirm Delete"
          message="Are you sure you want to delete this user?"
          confirmButtonText="Yes, Delete"
          cancelButtonText="Cancel"
        />
      </div>
    </Container>
  )
}

export default Loader(Users)
