import './styles.scss'

import AddIcon from '@mui/icons-material/Add'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import LocalDrinkIcon from '@mui/icons-material/LocalDrink'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { noImageAdded } from 'Assets/Images'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { IMAGE_URL } from 'Utils/Constants'

import Options from './Options'

const ItemModal = ({
  open,
  onClose,
  subCategory,
  onFormItemFormSubmit,
  setLoader,
  fetchUpdatedSubCategory, // Function to fetch updated items
}) => {
  const [formMode, setFormMode] = useState('ADD') // State to manage form mode
  const [isFormVisible, setFormVisible] = useState(false)
  const [formData, setFormData] = useState({
    itemId: '',
    itemName: '',
    itemPrice: '',
    itemDescription: '', // New field for item description
    subCategoryId: subCategory?.subCategoryId || '',
    categoryId: subCategory?.categoryId || '',
    restaurantId: subCategory?.restaurantId || '',
    itemImage: null,
    addOns: subCategory?.addOns || [],
    itemQuantities: subCategory?.itemQuantities || [],
    mixtures: subCategory?.mixtures || [],
    itemTaxRate: subCategory?.itemTaxRate || '',
  })
  const [errors, setErrors] = useState({})
  const [imagePreview, setImagePreview] = useState(null)
  const [isItemSaved, setIsItemSaved] = useState(false)
  const [isFetchingNewData, setIsFetchingNewData] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false) // Track if the form is being submitted

  useEffect(() => {
    if (!isFormVisible) {
      resetForm()
    }
  }, [isFormVisible, subCategory]) // Refresh form when subCategory changes

  const resetForm = () => {
    setFormMode('ADD') // Reset form mode to 'ADD'
    setFormData({
      itemId: '',
      itemName: '',
      itemPrice: '',
      itemDescription: '', // Reset description field
      subCategoryId: subCategory?.subCategoryId || '',
      categoryId: subCategory?.categoryId || '',
      restaurantId: subCategory?.restaurantId || '',
      itemImage: null,
      addOns: subCategory?.addOns || [],
      itemQuantities: subCategory?.itemQuantities || [],
      mixtures: subCategory?.mixtures || [],
      itemTaxRate: subCategory?.itemTaxRate || '',
    })
    setImagePreview(null)
    setErrors({})
    setIsItemSaved(false)
    setIsSubmitting(false) // Reset isSubmitting when the form is reset
  }

  const handleFormToggle = (item = null, toggleFormVisibility = true) => {
    if (item) {
      setFormData({
        itemId: item.itemId,
        itemName: item.itemName,
        itemPrice: item.itemPrice,
        itemDescription: item.itemDescription || '', // Populate description if available
        subCategoryId: subCategory?.subCategoryId || '',
        categoryId: subCategory?.categoryId || '',
        restaurantId: subCategory?.restaurantId || '',
        itemImage: null,
        addOns: item.addOns || [],
        itemQuantities: item.itemQuantities || [],
        mixtures: item.mixtures || [],
        itemTaxRate: item.itemTaxRate || '',
      })
      if (item.itemImage) {
        setImagePreview(`${IMAGE_URL}${item.itemImage}`)
      }
      setIsItemSaved(true)
      setFormMode('EDIT')
    } else {
      resetForm()
    }

    if (toggleFormVisibility) {
      setFormVisible(!isFormVisible)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']

    if (file && allowedTypes.includes(file.type)) {
      setFormData({ ...formData, itemImage: file })
      setImagePreview(URL.createObjectURL(file))
      setErrors({ ...errors, itemImage: '' })
    } else {
      setErrors({
        ...errors,
        itemImage: 'Only PNG, JPG, or JPEG images are allowed',
      })
    }
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    let errorMsg = ''

    if (!value) {
      errorMsg = 'Required'
    }
    setErrors({ ...errors, [name]: errorMsg })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const newErrors = {}

    if (!formData.itemName) {
      newErrors.itemName = 'Required'
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)

      return
    }

    setIsSubmitting(true) // Disable the form once the submission starts

    const payload = new FormData()

    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        payload.append(key, formData[key])
      }
    })

    setLoader(true)
    try {
      let response

      if (formMode === 'EDIT') {
        // Update existing item
        response = await apiAdapter.updateItem(formData.itemId, payload)
        swal('Success', 'Item updated successfully!', 'success')
      } else {
        // Add new item
        response = await apiAdapter.createItem(payload)
        swal('Success', 'Item created successfully!', 'success')

        // Fetch the updated subcategory and update form state with the newly added item
        setIsFetchingNewData(true)
        await fetchUpdatedSubCategory()
        setIsFetchingNewData(false)

        // Ensure form stays in edit mode with the newly added item
        handleFormToggle(response.data.data, false) // Pass 'false' to prevent toggling form visibility
      }
    } catch (error) {
      console.error('Error occurred:', error)
      swal('Error', error.response.data.message, 'error')
    } finally {
      setLoader(false)
      setIsSubmitting(false) // Re-enable the form after submission completes
    }
  }

  const onDismiss = () => {
    setFormVisible(false)
    setImagePreview(null)
    setFormData(null)
    setIsItemSaved(false)
    onClose()
  }

  return (
    <Modal open={open} onClose={onDismiss}>
      <Box className="modal-container">
        <div className="modal-header">
          <Typography variant="h6">
            {formMode === 'EDIT' ? 'Edit Item' : 'Add Item'}
          </Typography>
          <IconButton className="close-button" onClick={onDismiss}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-content">
          {isFormVisible ? (
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-section">
                  <div className="form-group">
                    <TextField
                      className="input-field"
                      label="Item Name"
                      name="itemName"
                      value={formData.itemName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.itemName}
                      helperText={errors.itemName}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      className="input-field"
                      label="Item Price"
                      name="itemPrice"
                      value={formData.itemPrice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.itemPrice}
                      helperText={errors.itemPrice}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </div>
                <div className="image-section">
                  <div className="image-preview">
                    {imagePreview ? (
                      <img src={imagePreview} alt="Preview" />
                    ) : (
                      'No Image'
                    )}
                  </div>
                  <label htmlFor="itemImage">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload-button"
                    >
                      Upload Image
                    </Button>
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    id="itemImage"
                  />
                  {errors.itemImage && (
                    <div className="error">{errors.itemImage}</div>
                  )}
                </div>
              </div>

              <div className="form-group full-width">
                <TextField
                  className="input-field"
                  label="Item Description"
                  name="itemDescription"
                  value={formData.itemDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.itemDescription}
                  helperText={errors.itemDescription}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>

              {isItemSaved && (
                <Options formData={formData} setFormData={setFormData} />
              )}

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="save-button"
                type="submit"
                disabled={isSubmitting} // Disable button if submitting
              >
                {formMode === 'EDIT' ? 'Update' : 'Save'}
              </Button>
            </form>
          ) : (
            <Box className="items-grid">
              {isFetchingNewData ? (
                <Typography variant="body1">Loading new data...</Typography>
              ) : (
                subCategory?.items.map((item) => (
                  <Card key={item.itemId} className="item-card">
                    <CardMedia
                      component="img"
                      height="140"
                      image={
                        item.itemImage
                          ? `${IMAGE_URL}${item.itemImage}`
                          : noImageAdded
                      }
                      alt={item.itemName}
                    />
                    <CardContent className="item-details">
                      <div className="text-details">
                        <Typography variant="h6">{item.itemName}</Typography>
                        <Typography variant="body2">
                          {item.itemPrice
                            ? `$${item.itemPrice}`
                            : 'Price not available'}
                        </Typography>
                      </div>

                      <div className="icon-details">
                        <div className="icon-row">
                          <Tooltip title={`Add-ons: ${item.addOns.length}`}>
                            <ShoppingCartIcon />
                          </Tooltip>
                          <Typography variant="body2" className="icon-text">
                            {item.addOns.length}
                          </Typography>
                        </div>
                        <div className="icon-row">
                          <Tooltip title={`Mixtures: ${item.mixtures.length}`}>
                            <LocalDrinkIcon />
                          </Tooltip>
                          <Typography variant="body2" className="icon-text">
                            {item.mixtures.length}
                          </Typography>
                        </div>
                        <div className="icon-row">
                          <Tooltip
                            title={`Item Quantities: ${item.itemQuantities.length}`}
                          >
                            <AddCircleOutlineIcon />
                          </Tooltip>
                          <Typography variant="body2" className="icon-text">
                            {item.itemQuantities.length}
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                    <IconButton
                      className="edit-icon"
                      onClick={() => handleFormToggle(item)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Card>
                ))
              )}
              <Card
                className="item-card add-card"
                onClick={() => handleFormToggle()}
              >
                <AddIcon
                  style={{ fontSize: 70, textAlign: 'center', height: '140px' }}
                />
                <CardContent className="add-card-title">
                  <Typography variant="h6">Add Item</Typography>
                </CardContent>
              </Card>
            </Box>
          )}
        </div>
      </Box>
    </Modal>
  )
}

export default Loader(ItemModal)
