import './styles.scss'

import Loader from 'components/Loader'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ResetPassword from './Components/ResetPassword'
import Signin from './Components/Signin'

const LoginComponent = ({ setLoader }) => {
  const navigate = useNavigate()
  const [viewMode, setViewMode] = useState('signin')

  const handleView = (res) => {
    console.log(res)
    setViewMode(res)
  }

  return (
    <div className="website-container">
      <div className="xsvenue-terminal">
        <div>
          <span className="text-wrapper">XS</span>
          <span className="span">Venue</span>
        </div>
        <div className="text-wrapper-2">Terminal Module</div>
      </div>

      {viewMode === 'signin' && (
        <Signin handleView={(event) => handleView(event)} />
      )}
      {viewMode === 'reset' && (
        <ResetPassword handleView={(event) => handleView(event)} />
      )}
    </div>
  )
}

export default Loader(LoginComponent)
