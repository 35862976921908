import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material'
import React from 'react'

const SalesTaxDialogModal = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    classes={{ paper: 'dialog-paper' }}
  >
    <DialogTitle className="dialog-title">
      <Box className="dialog-title-content">
        <Typography variant="h6" className="dialog-title-text">
          Sales & Tax
        </Typography>
        <IconButton onClick={onClose} className="close-button">
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent className="dialog-content">
      <Box className="dialog-section">
        <Box display="flex" alignItems="center">
          <Switch className="tax-switch" />
          <Typography variant="body1" className="switch-label">
            Non-taxable item
          </Typography>
        </Box>
      </Box>
      <Box className="dialog-section tax-list-section">
        <List>
          <ListItem className="tax-list-item">
            <Checkbox edge="start" className="tax-checkbox" />
            <ListItemText
              primary="GST (10%)"
              secondary="All Items"
              className="tax-item-text"
            />
            <ListItemSecondaryAction>
              <Typography variant="body2" className="venue-name">
                Venue Name
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Box>
      <Box className="dialog-section">
        <Typography variant="body2" className="info-text">
          Sales Taxes configured to apply to all items or categories are
          automatically applied to new items. Edit Sales Tax application rules
          in Accounts & Settings &gt; Sales Tax.
        </Typography>
      </Box>
      <Button fullWidth variant="contained" className="done-button">
        Done
      </Button>
    </DialogContent>
  </Dialog>
)

export default SalesTaxDialogModal
