import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { IMAGE_URL, STORAGE_KEYS } from 'Utils/Constants'
import { getLocal, setLocal } from 'Utils/LocalStorageHandler'

const OnboardRestaurant = ({
  isOpen,
  onClose,
  restaurantId,
  initialData = null,
  setLoader,
}) => {
  const userDetails = getLocal(STORAGE_KEYS.userId)
  const [formData, setFormData] = useState({
    restaurantName: '',
    adminPassword: '',
    city: '',
    adminFirstName: '',
    adminLastName: '',
    adminGender: '',
    adminMobileNo: '',
    adminEmail: '',
    restaurantPhone: '',
    restaurantEmail: '',
    restaurantStatus: '',
    locLatitude: '',
    locLongitude: '',
    state: '',
    country: '',
    restaurantAddress: '',
    restaurantPostalCode: '',
    restaurantStartTime: '',
    restaurantEndTime: '',
    restaurantTimezone: '',
    userRoleId: '',
    restaurantImage: null,
  })

  const [roles, setRoles] = useState([])
  const [errors, setErrors] = useState({})
  const [imagePreview, setImagePreview] = useState(null)

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (initialData) {
      setFormData({
        restaurantName: initialData.restaurantName || '',
        adminPassword: initialData.adminPassword || '',
        city: initialData.city || '',
        adminFirstName: initialData.adminFirstName || '',
        adminLastName: initialData.adminLastName || '',
        adminGender: initialData.adminGender || '',
        adminMobileNo: initialData.adminMobileNo || '',
        adminEmail: initialData.adminEmail || '',
        restaurantPhone: initialData.restaurantPhone || '',
        restaurantEmail: initialData.restaurantEmail || '',
        restaurantStatus: initialData.restaurantStatus || '',
        locLatitude: initialData.locLatitude || '',
        locLongitude: initialData.locLongitude || '',
        state: initialData.state || '',
        country: initialData.country || '',
        restaurantAddress: initialData.restaurantAddress || '',
        restaurantPostalCode: initialData.restaurantPostalCode || '',
        restaurantStartTime: initialData.restaurantStartTime || '',
        restaurantEndTime: initialData.restaurantEndTime || '',
        restaurantTimezone: initialData.restaurantTimezone || '',
        userRoleId: initialData.userRoleId || '',
        restaurantImage: null,
      })

      if (initialData.restaurantImage) {
        setImagePreview(`${IMAGE_URL}${initialData.restaurantImage}`)
      }
    }
  }, [initialData])

  const init = async () => {
    setLoader(true)
    try {
      const response = await apiAdapter.getAllActiveRoles()

      if (response.data.status) {
        const {
          data: { data: rolesData },
        } = response

        setRoles(rolesData)
      }
    } catch (error) {
      console.error('Error fetching roles:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleChange = (event) => {
    const { name, value, type, files } = event.target

    setFormData({ ...formData, [name]: type === 'file' ? files[0] : value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']

    if (file && allowedTypes.includes(file.type)) {
      setFormData({ ...formData, restaurantImage: file })
      setImagePreview(URL.createObjectURL(file))
      setErrors({ ...errors, restaurantImage: '' })
    } else {
      setErrors({
        ...errors,
        restaurantImage: 'Only PNG, JPG, or JPEG images are allowed',
      })
    }
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    let errorMsg = ''

    if (!value) errorMsg = 'Required'
    setErrors({ ...errors, [name]: errorMsg })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const newErrors = {}

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) newErrors[key] = 'Required'
    })

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)

      return
    }

    const formDataToSubmit = new FormData()

    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== '') {
        formDataToSubmit.append(key, value)
      }
    })

    setLoader(true)
    try {
      let response

      if (initialData) {
        response = await apiAdapter.updateRestaurantTable(
          initialData.restaurantTableId,
          formDataToSubmit,
        )
        swal('Success', 'Restaurant updated successfully!', 'success')
      } else {
        response = await apiAdapter.createRestaurant(formDataToSubmit)
        swal('Success', 'Restaurant created successfully!', 'success')
      }
      onClose()
    } catch (error) {
      console.error('Error occurred:', error)
      swal(
        'Error',
        'An error occurred while processing your request.',
        'error',
      )
    } finally {
      setLoader(false)
    }
  }

  const handleCloseModal = () => {
    setFormData({
      restaurantName: '',
      adminPassword: '',
      city: '',
      adminFirstName: '',
      adminLastName: '',
      adminGender: '',
      adminMobileNo: '',
      adminEmail: '',
      restaurantPhone: '',
      restaurantEmail: '',
      restaurantStatus: '',
      locLatitude: '',
      locLongitude: '',
      state: '',
      country: '',
      restaurantAddress: '',
      restaurantPostalCode: '',
      restaurantStartTime: '',
      restaurantEndTime: '',
      restaurantTimezone: '',
      userRoleId: '',
      restaurantImage: null,
    })
    setErrors({})
    setImagePreview(null)
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box className="modal-container">
        <div className="modal-header">
          <Typography variant="h6">
            {initialData ? 'Edit Restaurant' : 'Onboard Restaurant'}
          </Typography>
          <IconButton
            className="close-button"
            onClick={() => {
              handleCloseModal()
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-section">
                <div className="form-group">
                  <TextField
                    className="input-field"
                    label="Admin First Name"
                    name="adminFirstName"
                    value={formData.adminFirstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.adminFirstName}
                    helperText={errors.adminFirstName}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </div>
                <div className="form-group">
                  <TextField
                    className="input-field"
                    label="Admin Last Name"
                    name="adminLastName"
                    value={formData.adminLastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.adminLastName}
                    helperText={errors.adminLastName}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </div>
              </div>
              <div className="image-section">
                <div className="image-preview">
                  {imagePreview ? (
                    <img src={imagePreview} alt="Preview" />
                  ) : (
                    'No Image'
                  )}
                </div>
                <label htmlFor="restaurantImage">
                  <Button
                    variant="contained"
                    component="span"
                    className="upload-button"
                  >
                    Upload Image
                  </Button>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="restaurantImage"
                />
                {errors.restaurantImage && (
                  <div className="error">{errors.restaurantImage}</div>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Admin Mobile Number"
                  name="adminMobileNo"
                  value={formData.adminMobileNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.adminMobileNo}
                  helperText={errors.adminMobileNo}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Admin Email"
                  name="adminEmail"
                  type="email"
                  value={formData.adminEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.adminEmail}
                  helperText={errors.adminEmail}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Restaurant Name"
                  name="restaurantName"
                  value={formData.restaurantName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.restaurantName}
                  helperText={errors.restaurantName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Restaurant Phone"
                  name="restaurantPhone"
                  value={formData.restaurantPhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.restaurantPhone}
                  helperText={errors.restaurantPhone}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Restaurant Email"
                  name="restaurantEmail"
                  type="email"
                  value={formData.restaurantEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.restaurantEmail}
                  helperText={errors.restaurantEmail}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.userRoleId}
                >
                  <InputLabel id="role-dropdown">User Role</InputLabel>
                  <Select
                    labelId="role-dropdown"
                    id="role-dropdown-select"
                    value={formData.userRoleId}
                    name="userRoleId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="User Role"
                  >
                    {roles.length &&
                      roles.map((role) => (
                        <MenuItem key={role.userRoleId} value={role.userRoleId}>
                          {role.userRole}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.userRoleId && (
                    <FormHelperText>{errors.userRoleId}</FormHelperText>
                  )}
                </FormControl>
              </div>
              {/* <div className="form-group">
                <TextField
                  className="input-field"
                  label="User Role ID"
                  name="userRoleId"
                  value={formData.userRoleId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.userRoleId}
                  helperText={errors.userRoleId}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div> */}
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Restaurant Start Time"
                  name="restaurantStartTime"
                  type="time"
                  value={formData.restaurantStartTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.restaurantStartTime}
                  helperText={errors.restaurantStartTime}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="form-group">
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.restaurantStatus}
                >
                  <InputLabel id="status-dropdown">
                    Restaurant Status
                  </InputLabel>
                  <Select
                    className="dropdown"
                    labelId="status-dropdown"
                    id="status-dropdown-select"
                    value={formData.restaurantStatus}
                    name="restaurantStatus"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Restaurant Status"
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="In-active">In-active</MenuItem>
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                    <MenuItem value="DELETED">Deleted</MenuItem>
                  </Select>
                  {errors.restaurantStatus && (
                    <FormHelperText>{errors.restaurantStatus}</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Restaurant Address"
                  name="restaurantAddress"
                  value={formData.restaurantAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.restaurantAddress}
                  helperText={errors.restaurantAddress}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.city}
                  helperText={errors.city}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.state}
                  helperText={errors.state}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.country}
                  helperText={errors.country}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Postal Code"
                  name="restaurantPostalCode"
                  value={formData.restaurantPostalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.restaurantPostalCode}
                  helperText={errors.restaurantPostalCode}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Latitude"
                  name="locLatitude"
                  value={formData.locLatitude}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.locLatitude}
                  helperText={errors.locLatitude}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Longitude"
                  name="locLongitude"
                  value={formData.locLongitude}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.locLongitude}
                  helperText={errors.locLongitude}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Restaurant Timezone"
                  name="restaurantTimezone"
                  value={formData.restaurantTimezone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.restaurantTimezone}
                  helperText={errors.restaurantTimezone}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="Restaurant End Time"
                  name="restaurantEndTime"
                  type="time"
                  value={formData.restaurantEndTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.restaurantEndTime}
                  helperText={errors.restaurantEndTime}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="save-button"
              type="submit"
            >
              {initialData ? 'Update' : 'Save'}
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default Loader(OnboardRestaurant)
