import './styles.scss'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Rating } from '@mui/material'
import React, { useState } from 'react'

const ReviewCard = ({ review }) => (
  <div className="review-card">
    <div className="row order-card-header">
      <div className="order-card-date">{review.orderDate}</div>
      <div className="order-card-info">
        <div className="order-card-name">{review.name}</div>
        <div className="order-card-number">{review.orderId}</div>
      </div>
      <div className="order-card-amount">
        <CheckCircleRoundedIcon color="success"></CheckCircleRoundedIcon>{' '}
        <span className="status-icon">&#10003;</span>
        {review.orderValue}
      </div>
    </div>

    <div className="row order-card-body">
      <div className="order-card-status">
        {review.orderStatus}{' '}
        <Rating name="read-only" value={review.customerRating} readOnly />
      </div>
    </div>

    <div className="row order-card-footer">
      <div className="order-card-review">{review.customerReview}</div>
      <div className="order-card-time">Order Time - {review.orderTime}</div>
    </div>
  </div>
)

export default ReviewCard
