import './styles.scss'

import { Box, Rating } from '@mui/material'
import React, { useState } from 'react'

import ReviewCard from './Card'

const VenueDetailsComponent = () => {
  const [customerReviews, setCustomerReviews] = useState([
    {
      name: 'A. Smithson',
      orderId: '#227721',
      orderValue: 81.0,
      orderStatus: 'complete',
      customerReview: 'food is great',
      customerRating: 2.0, // Represented as a decimal for consistency
      maxRating: 3, // Added maxRating to make it clear that the rating is out of 3
      orderTime: '09:43',
      orderDate: '12/09/2023',
    },
    {
      name: 'A. Smithson',
      orderId: '#227721',
      orderValue: 81.0,
      orderStatus: 'complete',
      customerReview: 'food is great',
      customerRating: 2.0, // Represented as a decimal for consistency
      maxRating: 3, // Added maxRating to make it clear that the rating is out of 3
      orderTime: '09:43',
      orderDate: '12/09/2023',
    },
  ])

  return (
    <div className="reviews">
      <div className="container">
        <div className="header">
          <div className="title">Reviews</div>
          <div className="icon-container">
            <Box ml={'auto'} className="rating-icon">
              <div className="rating-heading">Rating</div>
              <Rating name="read-only" value={5} readOnly />
            </Box>
          </div>
        </div>

        <ul className="reviews-card-list">
          {customerReviews.map((review, index) => (
            <li key={index} className="reviews-card-item">
              <ReviewCard review={review} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default VenueDetailsComponent
