import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

const CategoryDialogModal = ({ open, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null)

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      classes={{ paper: 'dialog-paper' }}
    >
      <DialogTitle className="dialog-title">
        <Box className="dialog-title-content">
          <Typography variant="h6" className="dialog-title-text">
            Create or select a category
          </Typography>
          <IconButton onClick={onClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <Box className="dialog-section">
          <Typography variant="body1" className="section-label">
            Select a category
          </Typography>
          <Select fullWidth variant="outlined" className="select-field">
            <MenuItem value="">Select categories</MenuItem>
            <MenuItem value="Category1">Category1</MenuItem>
            <MenuItem value="Category2">Category2</MenuItem>
          </Select>
        </Box>
        <Box className="dialog-section">
          <Typography variant="body1" className="section-label">
            Create a category
          </Typography>
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            className="input-field"
          />
        </Box>
        <Box className="dialog-section">
          <Box className="upload-container">
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="contained-button-file" className="upload-label">
              <Box className="upload-content">
                <Typography variant="body2">
                  <i className="fa fa-image upload-icon"></i> Drag an image here
                  or upload. *
                </Typography>
              </Box>
            </label>
            {selectedFile && (
              <Typography variant="body2" className="file-name">
                {selectedFile.name}
              </Typography>
            )}
          </Box>
        </Box>
        <Button fullWidth variant="contained" className="save-button">
          Save
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default CategoryDialogModal
