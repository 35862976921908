import './styles.scss'

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@mui/icons-material/Search'
import SortIcon from '@mui/icons-material/Sort'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import { ROUTE_STRINGS, STORAGE_KEYS } from 'Utils/Constants'
import dummyData from 'Utils/Constants/dummyData/Items-lib.json'

const ItemList = () => {
  const navigate = useNavigate()
  const [items, setItems] = useState(dummyData)
  const [itemType, setItemType] = useState('')
  const [category, setCategory] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const body = {
      item_type: 0,
      category_id: 0,
      search: '',
      page: 1,
      limit: 10,
      restaurant_id: 1,
    }

    try {
      const restaurantItemsResponse = await apiAdapter.getRestaurantItems(body)

      // const restaurantItemsList = restaurantItemsResponse?.data?.returnLst
      // console.log(restaurantItemsResponse)
      setItems(dummyData)
    } catch (error) {
      // const statusCode = error?.response?.status
    } finally {
      // setLoading(false)
      // setShowTableMessage(false)
    }
  }

  const handleItemTypeChange = (event) => {
    setItemType(event.target.value)
  }

  const handleCategoryChange = (event) => {
    setCategory(event.target.value)
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const handleCreateItem = () => {
    // Handle create item functionality
  }

  return (
    <Container className="table-container">
      <div className="actions-section">
        <FormControl variant="outlined" className="formControl">
          <InputLabel id="item-type-label">Item type</InputLabel>
          <Select
            labelId="item-type-label"
            id="item-type"
            value={itemType}
            onChange={handleItemTypeChange}
            label="Item type"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Type 1</MenuItem>
            <MenuItem value={20}>Type 2</MenuItem>
            <MenuItem value={30}>Type 3</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="formControl">
          <InputLabel id="category-label">Categories</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={category}
            onChange={handleCategoryChange}
            label="Categories"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Category 1</MenuItem>
            <MenuItem value={20}>Category 2</MenuItem>
            <MenuItem value={30}>Category 3</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          placeholder="Search for an item"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          className="search-bar"
        />
        <FormControl variant="outlined" className="formControl">
          <InputLabel id="actions-label">Actions</InputLabel>
          <Select labelId="actions-label" id="actions" label="Actions">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Action 1</MenuItem>
            <MenuItem value={20}>Action 2</MenuItem>
            <MenuItem value={30}>Action 3</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(ROUTE_STRINGS.createItem)}
          className="create-item-button"
        >
          Create an item
        </Button>
      </div>

      <div className="table-section">
        <TableContainer component={Paper}>
          <Table className="table" aria-label="item list table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell">
                  <IconButton className="icon-button">
                    <SortIcon />
                  </IconButton>
                </TableCell>
                <TableCell className="table-cell">Type</TableCell>
                <TableCell className="table-cell">Variations</TableCell>
                <TableCell className="table-cell">Category</TableCell>
                <TableCell className="table-cell">Stock</TableCell>
                <TableCell className="table-cell">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {items.map((item) => (
                <TableRow key={item.id} className="table-row">
                  <TableCell className="table-cell checkbox-edit">
                    <Checkbox />
                    <Box display="flex" alignItems="center" gap={2}>
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{ width: 50, height: 50 }}
                      />
                      <Box>
                        <Typography
                          className="item-name"
                          variant="body2"
                          color="primary"
                        >
                          {item.name}
                        </Typography>
                        <IconButton className="edit-button">Edit</IconButton>
                        <Typography variant="caption" display="block">
                          Added {item.addedDate}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className="table-cell">{item.type}</TableCell>
                  <TableCell className="table-cell">
                    {item.variations.join(', ')}
                  </TableCell>
                  <TableCell className="table-cell">{item.category}</TableCell>
                  <TableCell className="table-cell">{item.stock}</TableCell>
                  <TableCell className="table-cell">
                    {item.price.join(' - ')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  )
}

export default ItemList
