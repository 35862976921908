import './styles.scss'

import { Button, Container, Paper, Typography } from '@material-ui/core'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import apiAdapter from 'Services/apiAdapter'
import { formatDate } from 'Utils/commonMethods'
import { STORAGE_KEYS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

const SalesSummary = ({ setLoader }) => {
  const { user } = getLocal(STORAGE_KEYS.userDetails)
  const restaurantId = user.restaurantId || location?.state?.restaurantId

  const [orders, setOrders] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [showApplyButton, setShowApplyButton] = useState(false)

  useEffect(() => {
    fetchSalesData()
  }, [currentPage])

  const fetchSalesData = async () => {
    setLoader(true)
    let formattedStartDate = startDate ? formatDate(startDate) : null
    let formattedEndDate = endDate ? formatDate(endDate) : null

    try {
      const response = await apiAdapter.getOrderHistoryByRestaurant({
        restaurantId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        page: currentPage,
        size: 10,
      })
      const {
        data: { data: salesData },
      } = response

      setOrders(salesData.content)
      setShowApplyButton(false)
    } catch (error) {
      console.error('Error fetching sales data:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleFilterApply = () => {
    setOrders([])
    setCurrentPage(0)
    fetchSalesData()
  }

  const handleDateChange = (setter) => (e) => {
    setter(new Date(e.target.value))
    setShowApplyButton(true)
  }

  const validOrders = orders.filter(
    (order) => order?.order?.createdOn && order?.order?.totalAmount,
  )

  const chartDataRecharts = validOrders.map((order) => ({
    date: order?.order?.createdOn
      ? new Date(order.order.createdOn).toLocaleDateString()
      : '',
    totalSales: order?.order?.totalAmount || 0,
  }))

  const itemsTotal = validOrders.reduce(
    (sum, order) => sum + (order?.order?.orderAmount || 0),
    0,
  )
  const serviceCharges = validOrders.reduce(
    (sum, order) => sum + (order?.order?.serviceCharges || 0),
    0,
  )
  const platformFee = validOrders.reduce(
    (sum, order) => sum + (order?.order?.platformFee || 0),
    0,
  )
  const taxAmount = validOrders.reduce(
    (sum, order) => sum + (order?.order?.taxAmount || 0),
    0,
  )
  const grossSales = validOrders.reduce(
    (sum, order) => sum + (order?.order?.totalAmount || 0),
    0,
  )
  const giftCardAmount = validOrders.reduce(
    (sum, order) => sum + (order?.order?.xsCreditsAmount || 0),
    0,
  )

  const paymentTypes = {
    prepaid: validOrders.reduce(
      (sum, order) =>
        sum +
        (order?.order?.paymentType === 'PREPAID' ? order.order.totalAmount : 0),
      0,
    ),
    postpaid: validOrders.reduce(
      (sum, order) =>
        sum +
        (order?.order?.paymentType === 'POSTPAID'
          ? order.order.totalAmount
          : 0),
      0,
    ),
    giftCard: giftCardAmount,
  }

  const taxOnPlatformFee = platformFee * 0.18
  const netTotal =
    paymentTypes.prepaid +
    paymentTypes.postpaid +
    paymentTypes.giftCard -
    (platformFee + taxOnPlatformFee)

  return (
    <Container className="summary-container">
      <div className="actions-section">
        <div className="left-section">
          <input
            type="date"
            value={startDate ? formatDate(startDate) : ''}
            onChange={handleDateChange(setStartDate)}
            placeholder="Start Date"
          />
          <input
            type="date"
            value={endDate ? formatDate(endDate) : ''}
            onChange={handleDateChange(setEndDate)}
            placeholder="End Date"
          />
          {showApplyButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFilterApply}
            >
              Apply
            </Button>
          )}
        </div>
        <div className="right-section"></div>
      </div>

      <Paper
        className="chart-container"
        style={{ marginTop: '20px', padding: '20px' }}
      >
        <Typography variant="h6">Total Sales</Typography>
        {validOrders.length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <RechartsLineChart data={chartDataRecharts}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="totalSales"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </RechartsLineChart>
          </ResponsiveContainer>
        ) : (
          <Typography>No data available</Typography>
        )}
      </Paper>

      {/* Sales and Payment Summary Section */}
      <Paper className="summary-section">
        <div className="summary-title">Sales</div>
        <div className="sales-summary">
          <div className="summary-row sub-title">
            <Typography variant="subtitle1">Product Sales</Typography>
          </div>
          <div className="summary-row m-l-40">
            <Typography>Items</Typography>
            <Typography>${itemsTotal.toFixed(2)}</Typography>
          </div>
          <div className="summary-row m-l-40">
            <Typography>Service Charges</Typography>
            <Typography>${serviceCharges.toFixed(2)}</Typography>
          </div>
          <div className="summary-row m-l-40">
            <Typography>Platform Fee</Typography>
            <Typography>${platformFee.toFixed(2)}</Typography>
          </div>
          <div className="summary-row">
            <Typography>Net Sales</Typography>
            <Typography>${itemsTotal.toFixed(2)}</Typography>
          </div>
          <div className="summary-row">
            <Typography>Gross Sales</Typography>
            <Typography>${grossSales.toFixed(2)}</Typography>
          </div>
          <div className="summary-row">
            <Typography>Total Sales</Typography>
            <Typography>${grossSales.toFixed(2)}</Typography>
          </div>
        </div>

        <div className="summary-title m-t-50">Payments</div>
        <div className="payments-summary">
          <div className="summary-row sub-title">
            <Typography variant="subtitle1">Total Collected</Typography>
          </div>
          <div className="summary-row m-l-40">
            <Typography>Prepaid</Typography>
            <Typography>${paymentTypes.prepaid.toFixed(2)}</Typography>
          </div>
          <div className="summary-row m-l-40">
            <Typography>Postpaid</Typography>
            <Typography>${paymentTypes.postpaid.toFixed(2)}</Typography>
          </div>
          <div className="summary-row m-l-40">
            <Typography>Gift Card</Typography>
            <Typography>${paymentTypes.giftCard.toFixed(2)}</Typography>
          </div>

          <div className="summary-row sub-title">
            <Typography variant="subtitle1">Fees</Typography>
          </div>
          <div className="summary-row m-l-40">
            <Typography>Platform Fee</Typography>
            <Typography>${platformFee.toFixed(2)}</Typography>
          </div>
          <div className="summary-row m-l-40">
            <Typography>Tax on Platform Fee</Typography>
            <Typography>${taxOnPlatformFee.toFixed(2)}</Typography>
          </div>
          <div className="summary-row">
            <Typography>Net Total</Typography>
            <Typography>${netTotal.toFixed(2)}</Typography>
          </div>
        </div>
      </Paper>
    </Container>
  )
}

export default Loader(SalesSummary)
