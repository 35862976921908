const handleRetry = () => {
  window.location.reload()
}

const getInitials = (role) => {
  return role
    .split('_')
    .map((word) => word.charAt(0))
    .join('')
}

const formatUserRole = (role) => {
  return role
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// Custom date formatting function
const formatDate = (date) => {
  const d = new Date(date)
  const year = d.getFullYear()
  const month = `0${d.getMonth() + 1}`.slice(-2) // Adding leading zero if needed
  const day = `0${d.getDate()}`.slice(-2) // Adding leading zero if needed

  return `${year}-${month}-${day}`
}

export { handleRetry, formatUserRole, getInitials, formatDate }
