import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

const ChangeItemTypeDialogModal = ({ open, onClose }) => {
  const [selectedValue, setSelectedValue] = useState('Beverage')

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      classes={{ paper: 'dialog-paper' }}
    >
      <DialogTitle className="dialog-title">
        <Box className="dialog-title-content">
          <Typography variant="h6" className="dialog-title-text">
            Change Item Type
          </Typography>
          <IconButton onClick={onClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <Typography className="dialog-description">
          Select an item type that fits your purpose.
        </Typography>
        <RadioGroup
          value={selectedValue}
          onChange={handleRadioChange}
          className="radio-group"
        >
          <Box className="radio-item">
            <FormControlLabel
              value="Beverage"
              control={<Radio />}
              label={
                <Box>
                  <Typography className="radio-label">Beverage</Typography>
                  <Typography className="radio-description">
                    This item will show under the beverage type. You can
                    customize categories, options, add ons & mixers.
                  </Typography>
                </Box>
              }
            />
            {selectedValue === 'Beverage' && (
              <Button className="current-type-button">Current type</Button>
            )}
          </Box>
          <Box className="radio-item">
            <FormControlLabel
              value="Food"
              control={<Radio />}
              label={
                <Box>
                  <Typography className="radio-label">Food</Typography>
                  <Typography className="radio-description">
                    This item will show under the food type. You can customize
                    categories, options, add ons & mixers.
                  </Typography>
                </Box>
              }
            />
          </Box>
        </RadioGroup>
        <Button fullWidth variant="contained" className="change-button">
          Change
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default ChangeItemTypeDialogModal
