import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'

const AddOptionsDialogModal = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    classes={{ paper: 'dialog-paper' }}
  >
    <DialogTitle className="dialog-title">
      <Box className="dialog-title-content">
        <Typography variant="h6" className="dialog-title-text">
          Add Options
        </Typography>
        <IconButton onClick={onClose} className="close-button">
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent className="dialog-content">
      <Typography className="dialog-description">
        Create option sets to group options. For example, a beverage can be
        served in a glass, bottle, or jug. A food item can be served in small or
        large.
      </Typography>
      <Box className="dialog-section">
        <Box display="flex" alignItems="center" className="input-group">
          <Typography className="input-label">Option set name</Typography>
          <InfoIcon className="info-icon" />
          <TextField variant="outlined" fullWidth className="input-field" />
        </Box>
      </Box>
      <Box className="dialog-section">
        <Typography className="option-set-title">*SET NAME* Options</Typography>
        <Button variant="outlined" fullWidth className="add-option-button">
          Add Option
        </Button>
      </Box>
      <Button fullWidth variant="contained" className="save-button">
        Save
      </Button>
    </DialogContent>
  </Dialog>
)

export default AddOptionsDialogModal
