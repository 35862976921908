import './styles.scss'

import { hotelBanner } from 'Assets/Images'
import React, { useEffect, useState } from 'react'

import OpeningTimes from './Components/OpeningTimes'
import Reviews from './Components/Reviews'
import VenueDetails from './Components/VenueDetails'

const VenueManagement = () => {
  const baseUrl = 'https://dev.xsworld.app/'
  const [venueDetails, setVenueDetails] = useState(null)

  useEffect(() => {
    const fetchRestaurantDetails = async () => {
      try {
        const response = await fetch(`${baseUrl}api/web/restaurants/show/74`)

        if (response.ok) {
          const restaurantData = await response.json()

          setVenueDetails(restaurantData)
          console.log(venueDetails, restaurantData)
        } else {
          console.error('Failed to fetch restaurant details')
        }
      } catch (error) {
        console.error(
          'Error occurred while fetching restaurant details:',
          error,
        )
      }
    }

    fetchRestaurantDetails()
  }, []) // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  return (
    <div className="website-component">
      <div className="content">
        <div className="hotel-banner">
          <img
            src={hotelBanner}
            className="hotel-banner-image"
            alt="hotel banner"
          />
        </div>

        <div className="second-half">
          <OpeningTimes />
          <VenueDetails />
          <Reviews />
        </div>
      </div>
    </div>
  )
}

export default VenueManagement
