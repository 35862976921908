import './styles.scss'

import { editIcon } from 'Assets/Images'
import React, { useState } from 'react'

const OpeningTimes = () => {
  const [venueTimings, setVenueTimings] = useState([
    { monday: '12 PM - 10 PM' },
    { tuesday: '12 PM - 10 PM' },
    { wednesday: '12 PM - 10 PM' },
    { thursday: '12 PM - 10 PM' },
    { friday: '12 PM - 10 PM' },
    { saturday: '12 PM - 10 PM' },
    { sunday: '12 PM - 10 PM' },
  ])

  return (
    <div className="opening-times">
      <div className="container">
        <div className="header">
          <div className="title">Opening Times</div>
          <div className="icon-container">
            <img src={editIcon} alt="edit" className="edit-button" />
          </div>
        </div>

        <ul className="opening-hours-list">
          {venueTimings.map((dayObject, index) => {
            const day = Object.keys(dayObject)[0]
            const hours = dayObject[day]

            return (
              <li key={index} className="opening-hours-item">
                <span className="day">
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </span>
                <span className="hours">{hours}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default OpeningTimes
