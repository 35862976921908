export const configUrl = {
  eureka: process.env.REACT_APP_BASE_URL_EUREKA,
  orderMicroservice: process.env.REACT_APP_BASE_URL_ORDER_MICROSERVICE,
  userMicroservice: process.env.REACT_APP_BASE_URL_USER_MICROSERVICE,
  restaurantService: process.env.REACT_APP_BASE_URL_RESTAURANT_SERVICE,
  eventService: process.env.REACT_APP_BASE_URL_EVENT_SERVICE,
  s3Service: process.env.REACT_APP_BASE_URL_S3_SERVICE,
  qrService: process.env.REACT_APP_BASE_URL_QR_SERVICE,
  userService: process.env.REACT_APP_BASE_URL_USER_SERVICE,
  accountService: process.env.REACT_APP_BASE_URL_ACCOUNT_SERVICE,
  orderService: process.env.REACT_APP_BASE_URL_ORDER_SERVICE,
}
