const STORAGE_KEYS = {
  authToken: 'authToken',
  isLoggedIn: 'isLoggedIn',
  userDetails: 'userDetails',
  userRole: 'userRole',
  userId: 'userId',
  restaurantName: 'restaurantName',
}

export default STORAGE_KEYS
