import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { IMAGE_URL } from 'Utils/Constants'

const CreateTableModal = ({
  isOpen,
  onClose,
  initialData = null,
  restaurantId,
  onSuccess,
}) => {
  const initialFormState = {
    restaurantId: restaurantId,
    restaurantTableIdentifier: '',
    restaurantTableName: '',
    paymentMode: 'PREPAID',
    restaurantTableIsAvailable: false,
    restaurantTableStatus: '',
    restaurantTableImage: null,
    qrCodeData: '',
  }

  const [formData, setFormData] = useState(initialFormState)
  const [errors, setErrors] = useState({})
  const [imagePreview, setImagePreview] = useState(null)

  useEffect(() => {
    if (initialData) {
      setFormData({
        restaurantId: initialData.restaurantId || restaurantId,
        restaurantTableIdentifier: initialData.restaurantTableIdentifier || '',
        restaurantTableName: initialData.restaurantTableName || '',
        paymentMode: initialData.paymentMode || 'PREPAID',
        restaurantTableIsAvailable:
          initialData.restaurantTableIsAvailable || false,
        restaurantTableStatus: initialData.restaurantTableStatus || '',
        restaurantTableImage: null,
        qrCodeData: initialData.qrCodeData || '',
      })

      if (initialData.restaurantTableImage) {
        setImagePreview(`${IMAGE_URL}${initialData.restaurantTableImage}`)
      }
    }
  }, [initialData, restaurantId])

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target

    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']

    if (file && allowedTypes.includes(file.type)) {
      setFormData({ ...formData, restaurantTableImage: file })
      setImagePreview(URL.createObjectURL(file))
      setErrors({ ...errors, restaurantTableImage: '' })
    } else {
      setErrors({
        ...errors,
        restaurantTableImage: 'Only PNG, JPG, or JPEG images are allowed',
      })
    }
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    let errorMsg = ''

    if (!value && (name === 'restaurantTableName' || name === 'paymentMode')) {
      errorMsg = 'Required'
    }

    setErrors({ ...errors, [name]: errorMsg })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const newErrors = {}

    if (!formData.restaurantTableName) {
      newErrors.restaurantTableName = 'Required'
    }
    if (!formData.paymentMode) {
      newErrors.paymentMode = 'Required'
    }
    if (!formData.restaurantTableImage && !initialData) {
      // Make image required if it's a new table
      newErrors.restaurantTableImage = 'Image is required'
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)

      return
    }

    try {
      const filteredFormData = new FormData()

      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== '') {
          filteredFormData.append(key, value)
        }
      })

      let response

      if (initialData) {
        response = await apiAdapter.updateRestaurantTable(
          initialData.restaurantTableId,
          filteredFormData,
        )
        swal('Success', 'Table updated successfully!', 'success')
      } else {
        response = await apiAdapter.createRestaurantTable(filteredFormData)
        swal('Success', 'Table created successfully!', 'success')
      }

      if (onSuccess) {
        onSuccess() // Call the onSuccess function to reload tables in parent component
      }

      handleClose() // Close the modal and clear state
    } catch (error) {
      console.error('Error occurred:', error)
      swal(
        'Error',
        error.response?.data?.message ||
          'An error occurred while processing your request.',
        'error',
      )
    }
  }

  const handleClose = () => {
    // Reset form state and close modal
    setFormData(initialFormState)
    setErrors({})
    setImagePreview(null)
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className="modal-container">
        <div className="modal-header">
          <Typography variant="h6">
            {initialData ? 'Edit Table' : 'Create Table'}
          </Typography>
          <IconButton className="close-button" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-section">
                <TextField
                  className="input-field"
                  label="Table Identifier"
                  name="restaurantTableIdentifier"
                  value={formData.restaurantTableIdentifier}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                <TextField
                  className="input-field"
                  label="Table Name"
                  name="restaurantTableName"
                  value={formData.restaurantTableName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.restaurantTableName}
                  helperText={errors.restaurantTableName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              <div className="image-section">
                <div className="image-preview">
                  {imagePreview ? (
                    <img src={imagePreview} alt="Preview" />
                  ) : (
                    'No Image'
                  )}
                </div>
                <label htmlFor="restaurantTableImage">
                  <Button
                    variant="contained"
                    component="span"
                    className="upload-button"
                  >
                    Upload Image
                  </Button>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="restaurantTableImage"
                />
                {errors.restaurantTableImage && (
                  <div className="error">{errors.restaurantTableImage}</div>
                )}
              </div>
            </div>

            <TextField
              className="input-field"
              label="Payment Mode"
              name="paymentMode"
              value={formData.paymentMode}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <TextField
              className="input-field"
              label="Table Status"
              name="restaurantTableStatus"
              value={formData.restaurantTableStatus}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.restaurantTableIsAvailable}
                  onChange={handleChange}
                  name="restaurantTableIsAvailable"
                />
              }
              label="Table is Available"
              className="input-field"
            />
            <TextField
              className="input-field"
              label="QR Code Data"
              name="qrCodeData"
              value={formData.qrCodeData}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              margin="normal"
              fullWidth
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="save-button"
              type="submit"
            >
              {initialData ? 'Update' : 'Save'}
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default CreateTableModal
