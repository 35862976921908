import './styles.scss'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import { Button, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import apiAdapter from 'Services/apiAdapter'

const Options = ({ formData, setFormData }) => {
  const [editingAddOns, setEditingAddOns] = useState(false)
  const [editingItemQuantities, setEditingItemQuantities] = useState(false)
  const [editingMixtures, setEditingMixtures] = useState(false)

  const toggleAddOns = () => {
    setEditingAddOns(!editingAddOns)
  }

  const toggleItemQuantities = () => {
    setEditingItemQuantities(!editingItemQuantities)
  }

  const toggleMixtures = () => {
    setEditingMixtures(!editingMixtures)
  }

  const handleAddRow = (section) => {
    if (section === 'addOns') {
      setFormData({
        ...formData,
        addOns: [
          ...formData.addOns,
          {
            addOnName: '',
            addOnPrice: '',
            isNew: true,
            itemId: formData.itemId,
          },
        ],
      })
    } else if (section === 'itemQuantities') {
      setFormData({
        ...formData,
        itemQuantities: [
          ...formData.itemQuantities,
          {
            itemQuantityName: '',
            itemQuantityDescription: '',
            itemQuantityPrice: '',
            isNew: true,
            itemId: formData.itemId,
          },
        ],
      })
    } else if (section === 'mixtures') {
      setFormData({
        ...formData,
        mixtures: [
          ...formData.mixtures,
          {
            mixtureName: '',
            mixturePrice: '',
            isNew: true,
            itemId: formData.itemId,
          },
        ],
      })
    }
  }

  const handleDeleteRow = async (section, index) => {
    let updatedData

    if (section === 'addOns') {
      if (!formData.addOns[index].isNew) {
        await apiAdapter.deleteAddOn(formData.addOns[index].addOnId)
      }
      updatedData = formData.addOns.filter((__, index) => index !== index)
      setFormData({ ...formData, addOns: updatedData })
    } else if (section === 'itemQuantities') {
      if (!formData.itemQuantities[index].isNew) {
        await apiAdapter.deleteItemQuantity(
          formData.itemQuantities[index].itemQuantityId,
        )
      }
      updatedData = formData.itemQuantities.filter(
        (__, index) => index !== index,
      )
      setFormData({ ...formData, itemQuantities: updatedData })
    } else if (section === 'mixtures') {
      if (!formData.mixtures[index].isNew) {
        await apiAdapter.deleteMixture(formData.mixtures[index].mixtureId)
      }
      updatedData = formData.mixtures.filter((__, index) => index !== index)
      setFormData({ ...formData, mixtures: updatedData })
    }
  }

  const handleSaveRow = async (section, index) => {
    let updatedData

    if (section === 'addOns') {
      const addOn = formData.addOns[index]
      let response

      if (addOn.isNew) {
        response = await apiAdapter.createAddOn(addOn)
        addOn.addOnId = response.data.addOnId // Assign the new ID
        delete addOn.isNew
      } else {
        response = await apiAdapter.updateAddOn(addOn.addOnId, addOn)
      }
      if (response.status) {
        delete addOn.isEdited // Remove edited flag
      }
      updatedData = [...formData.addOns]
      updatedData[index] = addOn
      setFormData({ ...formData, addOns: updatedData })
    } else if (section === 'itemQuantities') {
      const quantity = formData.itemQuantities[index]
      let response

      if (quantity.isNew) {
        response = await apiAdapter.createItemQuantity(quantity)
        quantity.itemQuantityId = response.data.itemQuantityId // Assign the new ID
        delete quantity.isNew
      } else {
        response = await apiAdapter.updateItemQuantity(
          quantity.itemQuantityId,
          quantity,
        )
      }
      if (response.status) {
        delete quantity.isEdited // Remove edited flag
      }
      updatedData = [...formData.itemQuantities]
      updatedData[index] = quantity
      setFormData({ ...formData, itemQuantities: updatedData })
    } else if (section === 'mixtures') {
      const mixture = formData.mixtures[index]
      let response

      if (mixture.isNew) {
        response = await apiAdapter.createMixture(mixture)
        mixture.mixtureId = response.data.mixtureId // Assign the new ID
        delete mixture.isNew
      } else {
        response = await apiAdapter.updateMixture(mixture.mixtureId, mixture)
      }
      if (response.status) {
        delete mixture.isEdited // Remove edited flag
      }
      updatedData = [...formData.mixtures]
      updatedData[index] = mixture
      setFormData({ ...formData, mixtures: updatedData })
    }
  }

  const renderTableRows = (section) => {
    return formData[section].map((item, index) => (
      <tr key={index}>
        <td>
          <TextField
            value={
              item[
                `${section === 'itemQuantities' ? 'itemQuantity' : section.slice(0, -1)}Name`
              ]
            }
            onChange={(event) => {
              const updatedItems = [...formData[section]]

              updatedItems[index][
                `${section === 'itemQuantities' ? 'itemQuantity' : section.slice(0, -1)}Name`
              ] = event.target.value
              updatedItems[index].isEdited = true
              setFormData({ ...formData, [section]: updatedItems })
            }}
          />
        </td>
        {section === 'itemQuantities' && (
          <td>
            <TextField
              value={item['itemQuantityDescription']}
              onChange={(event) => {
                const updatedItems = [...formData[section]]

                updatedItems[index]['itemQuantityDescription'] =
                  event.target.value
                updatedItems[index].isEdited = true
                setFormData({ ...formData, [section]: updatedItems })
              }}
            />
          </td>
        )}
        <td>
          <TextField
            value={
              item[
                `${section === 'itemQuantities' ? 'itemQuantity' : section.slice(0, -1)}Price`
              ]
            }
            onChange={(event) => {
              const updatedItems = [...formData[section]]

              updatedItems[index][
                `${section === 'itemQuantities' ? 'itemQuantity' : section.slice(0, -1)}Price`
              ] = event.target.value
              updatedItems[index].isEdited = true
              setFormData({ ...formData, [section]: updatedItems })
            }}
          />
        </td>
        <td>
          <div className="table-action-column">
            <IconButton
              className="table-action-button"
              onClick={() => handleSaveRow(section, index)}
              style={{
                display: item.isNew || item.isEdited ? 'inline-flex' : 'none',
              }}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              className="table-action-button"
              onClick={() => handleDeleteRow(section, index)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </td>
      </tr>
    ))
  }

  return (
    <>
      <div className="section">
        <div className="section-header">
          <Typography variant="h6">ADD-ONS</Typography>
          <Typography variant="body2">
            {formData.addOns?.length > 0
              ? formData.addOns.map((addOn) => addOn.addOnName).join(', ')
              : ''}
          </Typography>
          <Button variant="text" color="primary" onClick={toggleAddOns}>
            {editingAddOns
              ? 'Collapse'
              : formData.addOns?.length
                ? 'Edit'
                : 'Add'}
          </Button>
        </div>
        {editingAddOns && (
          <div className="section-table">
            <table>
              <thead>
                <tr>
                  <th>Add-On Name</th>
                  <th>Add-On Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows('addOns')}
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="table-action-column">
                      <IconButton
                        className="table-action-button"
                        onClick={() => handleAddRow('addOns')}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="section">
        <div className="section-header">
          <Typography variant="h6">Item Quantities</Typography>
          <Typography variant="body2">
            {formData.itemQuantities?.length
              ? formData.itemQuantities
                .map((quantity) => quantity.itemQuantityName)
                .join(', ')
              : ''}
          </Typography>
          <Button variant="text" color="primary" onClick={toggleItemQuantities}>
            {editingItemQuantities
              ? 'Collapse'
              : formData.itemQuantities?.length > 0
                ? 'Edit'
                : 'Add'}
          </Button>
        </div>
        {editingItemQuantities && (
          <div className="section-table">
            <table>
              <thead>
                <tr>
                  <th>Quantity Name</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows('itemQuantities')}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="table-action-column">
                      <IconButton
                        className="table-action-button"
                        onClick={() => handleAddRow('itemQuantities')}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="section">
        <div className="section-header">
          <Typography variant="h6">Mixers</Typography>
          <Typography variant="body2">
            {formData.mixtures?.length > 0
              ? formData.mixtures
                .map((mixture) => mixture.mixtureName)
                .join(', ')
              : ''}
          </Typography>
          <Button variant="text" color="primary" onClick={toggleMixtures}>
            {editingMixtures
              ? 'Collapse'
              : formData.mixtures?.length > 0
                ? 'Edit'
                : 'Add'}
          </Button>
        </div>
        {editingMixtures && (
          <div className="section-table">
            <table>
              <thead>
                <tr>
                  <th>Mixture Name</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows('mixtures')}
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="table-action-column">
                      <IconButton
                        className="table-action-button"
                        onClick={() => handleAddRow('mixtures')}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}

export default Options
