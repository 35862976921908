import './styles.scss'

import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { IMAGE_URL, ROUTE_STRINGS, STORAGE_KEYS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

import CreateTableModal from './Components/CreateTableModal'
import QRCodeModal from './Components/QRCodeModal'

const TableManagement = ({ setLoader }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = getLocal(STORAGE_KEYS.userDetails)
  const restaurantId = location?.state?.restaurantId || user.restaurantId

  const [tables, setTables] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [currentRowData, setCurrentRowData] = useState(null)
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false)
  const [qrCodeData, setQrCodeData] = useState('')

  useEffect(() => {
    if (restaurantId) {
      fetchTables()
    }
  }, [restaurantId])

  const fetchTables = async () => {
    setLoader(true)
    try {
      const response = await apiAdapter.getAllRestaurantTables(restaurantId)

      if (response.data.status) {
        setTables(response.data.data)
      }
    } catch (error) {
      console.error('Error occurred while fetching tables:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleOpenModal = (tableData = null) => {
    setCurrentRowData(tableData)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setCurrentRowData(null)
  }

  const handleDeleteTable = async (tableId) => {
    try {
      const response = await apiAdapter.deleteRestaurantTable(tableId)

      if (response.data.status) {
        swal('Success', 'Table deleted successfully!', 'success')
        fetchTables()
      }
    } catch (error) {
      console.error('Error occurred while deleting table:', error)
      swal(
        'Error',
        'An error occurred while processing your request.',
        'error',
      )
    }
  }

  const handleOpenQRCodeModal = (qrCode) => {
    setQrCodeData(qrCode)
    setQrCodeModalOpen(true)
  }

  const handleCloseQRCodeModal = () => {
    setQrCodeModalOpen(false)
    setQrCodeData('')
  }

  return (
    <Container className="table-container">
      <div className="actions-section">
        <div className="left-section"></div>
        <div className="right-section">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenModal()}
          >
            Create Table
          </Button>
        </div>
      </div>

      <div className="table-section">
        <TableContainer component={Paper}>
          <Table className="table" aria-label="restaurant tables">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell">Table Name</TableCell>
                <TableCell className="table-cell">Identifier</TableCell>
                <TableCell className="table-cell">Available</TableCell>
                <TableCell className="table-cell">Status</TableCell>
                <TableCell className="table-cell">QR Code</TableCell>
                <TableCell className="table-cell">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tables.map((table) => (
                <TableRow
                  key={table.restaurantTableId}
                  className="restaurant-table-row"
                >
                  <TableCell>{table.restaurantTableName}</TableCell>
                  <TableCell>{table.restaurantTableIdentifier}</TableCell>
                  <TableCell>
                    {table.restaurantTableIsAvailable ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell>{table.restaurantTableStatus}</TableCell>

                  {/* QR Code Cell */}
                  <TableCell>
                    <img
                      src={`data:image/png;base64,${table.qrCodeData}`}
                      alt="QR Code"
                      width="50"
                      height="50"
                      onClick={() => handleOpenQRCodeModal(table.qrCodeData)}
                      style={{ cursor: 'pointer' }}
                    />
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      className="roles-edit-button m-r-5"
                      onClick={() => handleOpenModal(table)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="roles-delete-button"
                      onClick={() => handleDeleteTable(table.restaurantTableId)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <CreateTableModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        initialData={currentRowData}
        restaurantId={restaurantId}
        onSuccess={fetchTables} // Pass fetchTables function to refresh table data on success
      />

      {/* QR Code Modal */}
      {qrCodeModalOpen && (
        <QRCodeModal base64STR={qrCodeData} onClose={handleCloseQRCodeModal} />
      )}
    </Container>
  )
}

export default Loader(TableManagement)
