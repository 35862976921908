import './styles.scss'

import React from 'react'

const NotAuthorized = () => {
  return (
    <div className="not-authorized-container">
      <h1>This page is not accessible to your role.</h1>
    </div>
  )
}

export default NotAuthorized
