import './styles.css'

import TuneIcon from '@mui/icons-material/Tune'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { logo } from 'Assets/Images'
import React, { useState } from 'react'
import { ROUTE_STRINGS, STORAGE_KEYS } from 'Utils/Constants'
import { ROLES } from 'Utils/Constants/RolesConfig'
import { getLocal, setLocal } from 'Utils/LocalStorageHandler'

const Header = ({ onMenuClick }) => {
  const userRole = getLocal(STORAGE_KEYS.userRole)
  const restaurantName = getLocal(STORAGE_KEYS.restaurantName)

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const handleMenuClick = () => {
    setIsSidebarOpen(!isSidebarOpen)
    if (onMenuClick) {
      onMenuClick()
    }
  }

  return (
    <AppBar
      position="static"
      className={`header-appbar ${isSidebarOpen ? 'sidebar-open' : ''}`}
    >
      <Toolbar className="header-toolbar">
        <div className="header-title">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
            className={`header-menu-icon ${isSidebarOpen ? '' : ''}`}
          >
            {/* collapsed */}
            <TuneIcon />
          </IconButton>
          <Typography variant="h6" className="header-title-text">
            Home
          </Typography>
        </div>

        <div className="header-logo">
          <img src={logo} alt="Logo" />
        </div>

        <div className="header-hotel-name">
          {userRole === ROLES.RESTAURANT_ADMIN && (
            <Typography variant="h6">{restaurantName}</Typography>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
