import './styles.scss'

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { logo } from 'Assets/Images'
import Loader from 'components/Loader'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formatUserRole, getInitials } from 'Utils/commonMethods'
import { ROUTE_STRINGS, STORAGE_KEYS } from 'Utils/Constants'
import { getRoleConfig, ROLES } from 'Utils/Constants/RolesConfig.js'
import { getLocal, setLocal } from 'Utils/LocalStorageHandler'

import ConfirmationModal from '../ConfirmationModal'

const Sidebar = ({ open, onClose, setLoader }) => {
  const drawerRef = useRef(null)
  const navigate = useNavigate()
  const isLoggedIn = getLocal(STORAGE_KEYS.isLoggedIn)

  const userRole = getLocal(STORAGE_KEYS.userRole)
  const restaurantName = getLocal(STORAGE_KEYS.restaurantName)
  const userDetails = getLocal(STORAGE_KEYS.userDetails)
  const { menuItems } = getRoleConfig(userRole)

  const [showSubMenu, setShowSubMenu] = useState(false)
  const [showMenuHeading, setShowMenuHeading] = useState('')
  const [subMenuItems, setSubMenuItems] = useState([])
  const [isConfirmationOpen, setConfirmationOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose()
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open, onClose])

  const handleMenuClick = (item) => {
    if (item.subMenu) {
      setShowMenuHeading(item.text)
      setSubMenuItems(item.subMenu)
      setShowSubMenu(true)
    } else if (item.action) {
      item.action()
    } else {
      onClose()
    }
  }

  const handleBackClick = () => {
    setShowSubMenu(false)
  }

  const handleLogout = () => {
    setConfirmationOpen(true)
  }

  const handleLogoutConfirm = () => {
    setConfirmationOpen(false)
    localStorage.clear()
    setLocal(STORAGE_KEYS.isLoggedIn, false)
    navigate(ROUTE_STRINGS.login, { replace: true })
    window.location.reload()
  }

  const handleLogoutClose = () => {
    setConfirmationOpen(false)
  }

  return (
    <>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        variant="persistent"
        classes={{ paper: 'sidebar-drawer' }}
        ref={drawerRef}
      >
        {!showSubMenu && (
          <>
            {userRole === ROLES.RESTAURANT_ADMIN && (
              <>
                <div className="sidebar-hotel-name">
                  <Typography variant="h6">{restaurantName}</Typography>
                </div>
                <Divider />
              </>
            )}
            <Box textAlign="center" p={2}>
              <div className="sidebar-title">
                <img className="sidebar-logo" src={logo} alt="logo" />
                <div className="XS-world-text">
                  <span className="text-wrapper">XS</span>
                  <span className="text-wrapper-2">World</span>
                </div>
              </div>
            </Box>
          </>
        )}

        {showSubMenu ? (
          <Box>
            <div className="action-section">
              <IconButton onClick={handleBackClick} className="back-button">
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="subtitle1" className="menu-item-name">
                {showMenuHeading}
              </Typography>
            </div>
            <List>
              {subMenuItems?.map((subItem, index) => (
                <ListItem
                  button
                  key={index}
                  component={Link}
                  to={subItem.link}
                  onClick={onClose}
                >
                  <ListItemText
                    primary={subItem.text}
                    className="sidebar-menu-item"
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        ) : (
          <List>
            {menuItems?.map((item, index) => (
              <ListItem
                button
                key={index}
                component={item.link ? Link : 'div'}
                to={item.link || undefined}
                onClick={() => handleMenuClick(item)}
              >
                <ListItemText
                  primary={item.text}
                  className="sidebar-menu-item"
                />
              </ListItem>
            ))}
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Sign Out" className="sidebar-menu-item" />
            </ListItem>
          </List>
        )}

        {isLoggedIn && (
          <Box className="sidebar-bottom">
            <Divider />
            <div className="sidebar-user-details">
              <Avatar className="sidebar-avatar">
                {getInitials(userRole)}
              </Avatar>
              <div className="sidebar-user-info">
                <Typography variant="body1">
                  {userDetails?.user.firstName} {userDetails?.user.lastName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formatUserRole(userRole)}
                </Typography>
              </div>
            </div>
          </Box>
        )}
      </Drawer>
      {isConfirmationOpen && (
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          onClose={handleLogoutClose}
          onConfirm={handleLogoutConfirm}
          title="Confirm Logout"
          message="Are you sure you want to log out?"
          confirmButtonText="Yes, Logout"
          cancelButtonText="Cancel"
        />
      )}
    </>
  )
}

export default Loader(Sidebar)
