import apiAdapter from '../Services/apiAdapter'
import { ROUTE_STRINGS, STORAGE_KEYS } from './Constants'

export const setLocal = (key, value) => {
  console.log('2', key, value)
  localStorage.setItem(key, JSON.stringify(value))
}
export const getLocal = (key) => {
  const value = localStorage.getItem(key)

  return JSON.parse(value)
}
export const coffeewebClearLocal = () => {
  localStorage.clear()
}

export const updateUserDetails = async () => {
  const userDetails = getLocal(STORAGE_KEYS.userDetails)
  const response = await apiAdapter.getuserbyuserid(userDetails?.id)

  setLocal(STORAGE_KEYS.userDetails, response.data)
}

export const redirectToHome = (history) => {
  history.push({
    pathname: ROUTE_STRINGS.login,
  })
}
