import './styles.scss'

import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'

const Categories = () => (
  <Container className="development-container">
    <div className="development-box">
      <Typography variant="h2" className="development-title">
        Development is in Progress
      </Typography>
      <Typography variant="body1" className="development-message">
        This page is currently under development. Please check back later for
        updates.
      </Typography>
      <Button variant="contained" className="home-button" href="/">
        Go to Home
      </Button>
    </div>
  </Container>
)

export default Categories
