import './styles.scss'

import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

const Venues = () => {
  const navigate = useNavigate()
  const userId = getLocal('userId')

  const [venues, setVenues] = useState([])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      const response = await apiAdapter.getEvents()

      if (response.data.status) {
        setVenues(response.data.data)
      }
    } catch (error) {
      console.error('Error occurred while fetching venues:', error)
    }
  }

  return (
    <Container className="table-container">
      <div className="actions-section">
        <div className="left-section"></div>
        <div className="right-section">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(ROUTE_STRINGS.onboardVenue)}
          >
            Onboard Venue
          </Button>
        </div>
      </div>

      <div className="table-section">
        <TableContainer component={Paper}>
          <Table className="table" aria-label="venues table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell">Event Name</TableCell>
                <TableCell className="table-cell">Phone</TableCell>
                <TableCell className="table-cell">Email</TableCell>
                <TableCell className="table-cell">Start Time</TableCell>
                <TableCell className="table-cell">Status</TableCell>
                <TableCell className="table-cell">Address</TableCell>
                <TableCell className="table-cell">Postal Code</TableCell>
                <TableCell className="table-cell">City</TableCell>
                <TableCell className="table-cell">State</TableCell>
                <TableCell className="table-cell">End Time</TableCell>
                <TableCell className="table-cell">Country</TableCell>
                <TableCell className="table-cell">Timezone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {venues.map((venue) => (
                <TableRow key={venue.eventEmail} className="venues-table-row">
                  <TableCell>{venue.eventName}</TableCell>
                  <TableCell>{venue.eventPhone}</TableCell>
                  <TableCell>{venue.eventEmail}</TableCell>
                  <TableCell>{venue.eventStarttime}</TableCell>
                  <TableCell>{venue.eventStatus}</TableCell>
                  <TableCell>{venue.eventAddress}</TableCell>
                  <TableCell>{venue.eventPostalCode}</TableCell>
                  <TableCell>{venue.city}</TableCell>
                  <TableCell>{venue.state}</TableCell>
                  <TableCell>{venue.eventEndtime}</TableCell>
                  <TableCell>{venue.countryName}</TableCell>
                  <TableCell>{venue.eventTimezone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  )
}

export default Venues
