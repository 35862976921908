import './styles.scss'

import { Box, Button, TextField, Typography } from '@mui/material'
import { logo } from 'Assets/Images'
import Loader from 'components/Loader'
import { jwtDecode } from 'jwt-decode'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { ROUTE_STRINGS, STORAGE_KEYS } from 'Utils/Constants'
import { setLocal } from 'Utils/LocalStorageHandler'
import { emailValidation, passwordValidation } from 'Utils/Validation'

const Signin = ({ handleView, setLoader }) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    const { name, value } = event.target

    setFormData({ ...formData, [name]: value })

    // Clear errors on change
    setErrors({ ...errors, [name]: '' })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    let errorMsg = ''

    if (!value && name === 'email') {
      errorMsg = 'Email is required'
    } else if (name === 'email' && !emailValidation(value)) {
      errorMsg = 'Invalid email address'
    }

    if (!value && name === 'password') {
      errorMsg = 'Password is required'
    } else if (name === 'password' && !passwordValidation(value)) {
      errorMsg = 'Invalid Password'
    }

    setErrors({ ...errors, [name]: errorMsg })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // Validate before submission
    const newErrors = {}

    if (!formData.email) {
      newErrors.email = 'Email is required'
    } else if (!emailValidation(formData.email)) {
      newErrors.email = 'Invalid email address'
    }

    if (!formData.password) {
      newErrors.password = 'Password is required'
    } else if (!passwordValidation(formData.password)) {
      newErrors.password = 'Invalid Password --'
    }

    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) {
      return
    }

    setLoader(true)
    try {
      const response = await apiAdapter.login({
        email: formData.email,
        password: formData.password,
      })

      console.log('response-login', response)

      if (response.status === 200) {
        const { jwt, status } = response.data
        const user = jwtDecode(jwt)

        setLocal(STORAGE_KEYS.authToken, jwt)
        setLocal(STORAGE_KEYS.userRole, user.roles[0])
        setLocal(STORAGE_KEYS.userId, user.jti)
        setLocal(STORAGE_KEYS.isLoggedIn, status)

        navigate(ROUTE_STRINGS.home, { replace: true })
        window.location.reload()
      } else {
        swal('Error', response.data.message, 'error')
      }
    } catch (error) {
      swal('Error', error.response.data.message, 'error')
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="signin-container">
      <div className="XS-world">
        <div>
          <img className="logo" src={logo} alt="logo" />
        </div>
        <div className="XS-world-text">
          <span className="text-wrapper">XS</span>
          <span className="text-wrapper-2">World</span>
        </div>
      </div>

      <div className="text-wrapper-6">Sign In</div>

      <div className="div">
        <form className="signin-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="text-wrapper-4">
              <TextField
                className="input-field"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.email}
                helperText={errors.email}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>

          <div className="form-row m-t-20">
            <div className="text-wrapper-4">
              <TextField
                className="input-field"
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.password}
                helperText={errors.password}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>

          <div className="text-wrapper-5">
            <span
              className="forgot-pass-link"
              onClick={() => handleView('reset')}
            >
              Forgot password?
            </span>
          </div>
          <div className="button-row">
            <div className="overlap-group">
              <button className="text-wrapper-3" onClick={handleSubmit}>
                Sign in
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Loader(Signin)
