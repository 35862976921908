import './styles.scss'

import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {
  Done,
  HourglassEmpty,
  LocalMall,
  Restaurant,
  Schedule,
} from '@material-ui/icons' // Importing icons for order types and status
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import { STORAGE_KEYS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

const formatDate = (date) => {
  const d = new Date(date)
  const year = d.getFullYear()
  const month = `0${d.getMonth() + 1}`.slice(-2)
  const day = `0${d.getDate()}`.slice(-2)

  return `${year}-${month}-${day}`
}

const OrderHistory = ({ setLoader }) => {
  const { user } = getLocal(STORAGE_KEYS.userDetails)
  const restaurantId = user.restaurantId || location?.state?.restaurantId

  const [orders, setOrders] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [showApplyButton, setShowApplyButton] = useState(false)

  useEffect(() => {
    fetchOrderHistory()
  }, [currentPage])

  const fetchOrderHistory = async () => {
    setLoader(true)
    let formattedStartDate = startDate ? formatDate(startDate) : null
    let formattedEndDate = endDate ? formatDate(endDate) : null

    try {
      const response = await apiAdapter.getOrderHistoryByRestaurant({
        restaurantId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        page: currentPage,
        size: 10,
      })
      const {
        data: { data: reportsData },
      } = response

      setOrders(reportsData.content)
    } catch (error) {
      console.error('Error fetching order history:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleFilterApply = () => {
    setOrders([])
    setCurrentPage(0)
    fetchOrderHistory()
  }

  const handleDateChange = (setter) => (e) => {
    setter(new Date(e.target.value))
    setShowApplyButton(true)
  }

  // Function to render icons and text for order types
  const renderOrderType = (orderType) => {
    switch (orderType) {
      case 'PICKUP_ORDER':
        return (
          <>
            <LocalMall style={{ verticalAlign: 'middle', marginRight: 4 }} />
            Pick up
          </>
        )
      case 'SCHEDULED_PICKUP_ORDER':
        return (
          <>
            <Schedule style={{ verticalAlign: 'middle', marginRight: 4 }} />
            Scheduled
          </>
        )
      case 'TABLE_ORDER':
        return (
          <>
            <Restaurant style={{ verticalAlign: 'middle', marginRight: 4 }} />
            Table
          </>
        )
      default:
        return orderType
    }
  }

  // Function to render icons and text for order status
  const renderOrderStatus = (orderStatus) => {
    switch (orderStatus) {
      case 'COMPLETED':
        return (
          <>
            <Done style={{ verticalAlign: 'middle', marginRight: 4 }} />
            Completed
          </>
        )
      case 'IN_PROGRESS':
        return (
          <>
            <HourglassEmpty
              style={{ verticalAlign: 'middle', marginRight: 4 }}
            />
            Progress
          </>
        )
      default:
        return orderStatus
    }
  }

  return (
    <Container className="table-container">
      <div className="actions-section">
        <div className="left-section">
          <input
            type="date"
            value={startDate ? formatDate(startDate) : ''}
            onChange={handleDateChange(setStartDate)}
            placeholder="Start Date"
          />
          <input
            type="date"
            value={endDate ? formatDate(endDate) : ''}
            onChange={handleDateChange(setEndDate)}
            placeholder="End Date"
          />
          {showApplyButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFilterApply}
            >
              Apply
            </Button>
          )}
        </div>
        <div className="right-section"></div>
      </div>

      <div className="table-section">
        <TableContainer component={Paper}>
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Order Description</TableCell>
                <TableCell>Order Amount</TableCell>
                <TableCell>Order Status</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Order Type</TableCell>
                <TableCell>Created On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order.order.orderId}>
                  <TableCell>{order.order.orderId}</TableCell>
                  <TableCell>{order.order.orderDescription}</TableCell>
                  <TableCell>{order.order.totalAmount}</TableCell>
                  <TableCell>
                    {renderOrderStatus(order.order.orderStatus)}
                  </TableCell>
                  <TableCell>{order.order.paymentStatus}</TableCell>
                  <TableCell>
                    {renderOrderType(order.order.orderType)}
                  </TableCell>
                  <TableCell>{formatDate(order.order.createdOn)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  )
}

export default Loader(OrderHistory)
