import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { IMAGE_URL } from 'Utils/Constants'

const AddCategoryModal = ({
  isOpen,
  onClose,
  restaurantId,
  initialData = null,
  setLoader,
}) => {
  const [formData, setFormData] = useState({
    categoryName: '',
    restaurantId: restaurantId,
    categoryImage: null,
    categoryDescription: '',
  })

  const [errors, setErrors] = useState({})
  const [imagePreview, setImagePreview] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (initialData) {
      setFormData({
        categoryName: initialData.categoryName || '',
        restaurantId: initialData.restaurantId || restaurantId,
        categoryImage: null,
        categoryDescription: initialData.categoryDescription || '',
      })

      if (initialData.categoryImage) {
        setImagePreview(`${IMAGE_URL}${initialData.categoryImage}`)
      }
    }
  }, [initialData, restaurantId])

  const handleChange = (event) => {
    const { name, value } = event.target

    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']

    if (file && allowedTypes.includes(file.type)) {
      setFormData({ ...formData, categoryImage: file })
      setImagePreview(URL.createObjectURL(file))
      setErrors({ ...errors, categoryImage: '' })
    } else {
      setErrors({
        ...errors,
        categoryImage: 'Only PNG, JPG, or JPEG images are allowed',
      })
    }
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    let errorMsg = ''

    if (!value) {
      errorMsg = 'Required'
    }

    setErrors({ ...errors, [name]: errorMsg })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const newErrors = {}

    if (!formData.categoryName) {
      newErrors.categoryName = 'Required'
    }

    if (!formData.categoryImage && !initialData) {
      newErrors.categoryImage = 'Required'
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)

      return
    }

    setLoader(true)
    setLoading(true)
    const payload = new FormData()

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && formData[key] !== '') {
        payload.append(key, formData[key])
      }
    })

    try {
      let response

      if (initialData) {
        response = await apiAdapter.updateCategory(
          initialData.categoryId,
          payload,
        )
        swal('Success', 'Category updated successfully!', 'success')
      } else {
        response = await apiAdapter.createCategory(payload)
        swal('Success', 'Category created successfully!', 'success')
      }

      onClose()
    } catch (error) {
      swal('Error', error.response.data.message, 'error')
    } finally {
      setLoader(false)
      setLoading(false)
    }
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="modal-container">
        <div className="modal-header">
          <Typography variant="h6">
            {initialData ? 'Edit Category' : 'Add Category'}
          </Typography>
          <IconButton className="close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-section">
                <div className="form-group">
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Category Name</InputLabel>
                    <Select
                      label="Category Name"
                      name="categoryName"
                      value={formData.categoryName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.categoryName}
                    >
                      <MenuItem value="FOOD">FOOD</MenuItem>
                      <MenuItem value="BEVERAGES">BEVERAGES</MenuItem>
                    </Select>
                    {errors.categoryName && (
                      <Typography color="error" variant="body2">
                        {errors.categoryName}
                      </Typography>
                    )}
                  </FormControl>
                </div>
                <div className="form-group">
                  <TextField
                    className="input-field"
                    label="Category Description"
                    name="categoryDescription"
                    value={formData.categoryDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.categoryDescription}
                    helperText={errors.categoryDescription}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </div>
              </div>
              <div className="image-section">
                <div className="image-preview">
                  {imagePreview ? (
                    <img src={imagePreview} alt="Preview" />
                  ) : (
                    'No Image'
                  )}
                </div>
                <label htmlFor="categoryImage">
                  <Button
                    variant="contained"
                    component="span"
                    className="upload-button"
                  >
                    Upload Image
                  </Button>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="categoryImage"
                />
                {errors.categoryImage && (
                  <div className="error">{errors.categoryImage}</div>
                )}
              </div>
            </div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="save-button"
              type="submit"
              disabled={loading}
            >
              {initialData ? 'Update' : 'Save'}
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default Loader(AddCategoryModal)
