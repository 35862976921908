import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import React from 'react'

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title = 'Confirm Action',
  message = 'Are you sure you want to proceed?',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="confirmation-modal-container">
        {/* Modal Header */}
        <div className="confirmation-modal-header">
          <Typography variant="h6">{title}</Typography>
          <IconButton onClick={onClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </div>

        {/* Modal Content */}
        <div className="confirmation-modal-content">
          <Typography variant="body1" gutterBottom>
            {message}
          </Typography>
        </div>

        {/* Modal Footer */}
        <div className="confirmation-modal-footer">
          <Button
            variant="contained"
            color="primary"
            className="confirm-button"
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="cancel-button"
            onClick={onClose}
          >
            {cancelButtonText}
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default ConfirmationModal
