import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { IMAGE_URL, ROUTE_STRINGS } from 'Utils/Constants'

const AddSubCategoryModal = ({
  isOpen,
  onClose,
  initialData = null,
  restaurantId,
  categoryId,
  setLoader,
}) => {
  const [formData, setFormData] = useState({
    categoryId: categoryId || '',
    subCategoryId: '',
    restaurantId: restaurantId || '',
    subCategoryName: '',
    subCategoryDescription: '',
    subCategoryImage: null,
  })

  const [errors, setErrors] = useState({})
  const [imagePreview, setImagePreview] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (initialData) {
      setFormData({
        categoryId: initialData.categoryId || categoryId || '',
        subCategoryId: initialData.subCategoryId || '',
        restaurantId: initialData.restaurantId || restaurantId || '',
        subCategoryName: initialData.subCategoryName || '',
        subCategoryImage: null,
        subCategoryDescription: initialData.subCategoryDescription || '',
      })
      if (initialData.subCategoryImage) {
        setImagePreview(`${IMAGE_URL}${initialData.subCategoryImage}`)
      }
    } else {
      resetForm()
    }
  }, [initialData, restaurantId, categoryId])

  const resetForm = () => {
    setFormData({
      categoryId: categoryId || '',
      subCategoryId: '',
      restaurantId: restaurantId || '',
      subCategoryName: '',
      subCategoryDescription: '',
      subCategoryImage: null,
    })
    setImagePreview(null)
    setErrors({})
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']

    if (file && allowedTypes.includes(file.type)) {
      setFormData({ ...formData, subCategoryImage: file })
      setImagePreview(URL.createObjectURL(file))
      setErrors({ ...errors, subCategoryImage: '' })
    } else {
      setErrors({
        ...errors,
        subCategoryImage: 'Only PNG, JPG, or JPEG images are allowed',
      })
    }
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    let errorMsg = ''

    if (!value && name === 'subCategoryName') {
      errorMsg = 'Required'
    }
    setErrors({ ...errors, [name]: errorMsg })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const newErrors = {}

    if (!formData.subCategoryName) {
      newErrors.subCategoryName = 'Required'
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)

      return
    }

    setLoader(true)
    setLoading(true)
    const payload = new FormData()

    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        payload.append(key, formData[key])
      }
    })

    try {
      let response

      if (initialData) {
        // TODO :: This API form is Json type so passing object as it is.
        response = await apiAdapter.updateSubCategory(
          formData.subCategoryId,
          payload,
        )
        swal('Success', 'Subcategory updated successfully!', 'success')
      } else {
        response = await apiAdapter.createSubCategory(payload)
        swal('Success', 'Subcategory created successfully!', 'success')
      }
      onClose()
    } catch (error) {
      console.log('Error occurred:', error)
      swal('Error', error, 'error')
    } finally {
      setLoader(false)
      setLoading(false)
    }
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="modal-container">
        <div className="modal-header">
          <Typography variant="h6">
            {initialData ? 'Edit Subcategory' : 'Add Subcategory'}
          </Typography>
          <IconButton className="close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-section">
                <div className="form-group">
                  <TextField
                    className="input-field"
                    label="Subcategory Name"
                    name="subCategoryName"
                    value={formData.subCategoryName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.subCategoryName}
                    helperText={errors.subCategoryName}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </div>
                <div className="form-group">
                  <TextField
                    className="input-field"
                    label="Subcategory Description"
                    name="subCategoryDescription"
                    value={formData.subCategoryDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.subCategoryDescription}
                    helperText={errors.subCategoryDescription}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </div>
              </div>
              <div className="image-section">
                <div className="image-preview">
                  {imagePreview ? (
                    <img src={imagePreview} alt="Preview" />
                  ) : (
                    'No Image'
                  )}
                </div>
                <label htmlFor="subCategoryImage">
                  <Button
                    variant="contained"
                    component="span"
                    className="upload-button"
                  >
                    Upload Image
                  </Button>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="subCategoryImage"
                />
                {errors.subCategoryImage && (
                  <div className="error">{errors.subCategoryImage}</div>
                )}
              </div>
            </div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="save-button"
              type="submit"
              disabled={loading}
            >
              {initialData ? 'Update' : 'Save'}
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default Loader(AddSubCategoryModal)
