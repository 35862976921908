import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import Header from 'components/Header'
import MyErrorBoundary from 'components/MyErrorBoundary'
import ProtectedRoute from 'components/ProtectedRoute'
import Sidebar from 'components/Sidebar'
import Authentication from 'pages/Authentication'
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { ROUTE_STRINGS, STORAGE_KEYS } from 'Utils/Constants'
import { ROLES } from 'Utils/Constants/RolesConfig'
import { getLocal } from 'Utils/LocalStorageHandler'

import Categories from './pages/Categories'
import Dashboard from './pages/Dashboard'
import ItemLibrary from './pages/ItemLibrary'
import CreateItem from './pages/ItemLibrary/Components/CreateItem'
import Modifiers from './pages/Modifiers'
import NotAuthorized from './pages/NotAuthorized'
import OrderHistory from './pages/OrderHistory'
import Restaurant from './pages/RestaurantManagement'
import OnboardRestaurant from './pages/RestaurantManagement/Components/OnboardRestaurant'
import TableManagement from './pages/RestaurantManagement/Components/TableManagement/index'
import ViewRestaurantInfo from './pages/RestaurantManagement/Components/ViewRestaurantInfo'
import Roles from './pages/Roles'
import CreateRole from './pages/Roles/Components/CreateRole'
import SalesSummary from './pages/SalesSummary'
import Users from './pages/Users'
import VenueManagement from './pages/VenueManagement'
import OnboardVenue from './pages/VenueManagement/Components/OnboardVenue'
import Venue from './pages/VenueManagementOLD'
import theme from './theme/Theme'

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(
    getLocal(STORAGE_KEYS.isLoggedIn),
  )

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === STORAGE_KEYS.isLoggedIn) {
        const res = getLocal(STORAGE_KEYS.isLoggedIn)

        setIsLoggedIn(res)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    const checkLoginStatus = () => {
      const loggedIn = getLocal(STORAGE_KEYS.isLoggedIn)

      if (loggedIn !== isLoggedIn) {
        setIsLoggedIn(loggedIn)
      }
    }

    checkLoginStatus()
  }, [isLoggedIn])

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MyErrorBoundary>
        <Router>
          {isLoggedIn && <Header onMenuClick={handleSidebarToggle} />}
          {isLoggedIn && (
            <Sidebar
              open={sidebarOpen}
              onClose={() => handleSidebarToggle(false)}
            />
          )}

          <Routes>
            {/* Public Routes */}
            <Route path={ROUTE_STRINGS.login} element={<Authentication />} />
            <Route path={ROUTE_STRINGS.auth} element={<Authentication />} />

            {/* Protected Routes */}
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={[ROLES.SUPER_ADMIN, ROLES.RESTAURANT_ADMIN]}
                />
              }
            >
              <Route
                path={ROUTE_STRINGS.home}
                element={<Dashboard onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.venue}
                element={<Venue onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.item}
                element={<ItemLibrary onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.createItem}
                element={<CreateItem onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.categories}
                element={<Categories onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.modifers}
                element={<Modifiers onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.orderHistory}
                element={<OrderHistory onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.salesSummary}
                element={<SalesSummary onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.roles}
                element={<Roles onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.users}
                element={<Users onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.createRole}
                element={<CreateRole onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.listRestaurants}
                element={<Restaurant onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.onboardRestaurant}
                element={
                  <OnboardRestaurant onMenuClick={handleSidebarToggle} />
                }
              />
              <Route
                path={ROUTE_STRINGS.tableManagement}
                element={<TableManagement onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.viewRestaurantInfo}
                element={
                  <ViewRestaurantInfo onMenuClick={handleSidebarToggle} />
                }
              />
              <Route
                path={ROUTE_STRINGS.venueManagement}
                element={<VenueManagement onMenuClick={handleSidebarToggle} />}
              />
              <Route
                path={ROUTE_STRINGS.onboardVenue}
                element={<OnboardVenue onMenuClick={handleSidebarToggle} />}
              />
            </Route>

            {/* Not Authorized Route */}
            <Route path="/not-authorized" element={<NotAuthorized />} />

            {/* Catch-all route */}
            <Route
              path="*"
              element={
                <Navigate
                  to={isLoggedIn ? ROUTE_STRINGS.home : ROUTE_STRINGS.login}
                />
              }
            />
          </Routes>
        </Router>
      </MyErrorBoundary>
    </ThemeProvider>
  )
}

export default App
