// src/theme/Theme.js
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#eac36c',
    },
    background: {
      default: '#0F0E0E',
      paper: '#575757',
    },
    text: {
      primary: '#ffffff',
    },
    accent: {
      backgroundColor: '#575757',
      color: '#000',
    },
  },
})

export default theme

// import { createTheme } from '@material-ui/core/styles';
// import { red } from '@material-ui/core/colors';

// const theme = createTheme({
//   palette: {
//     type: 'dark',
//     primary: {
//       main: '#000000', // Customize your primary color
//     },
//     secondary: {
//       main: '#ffffff', // Customize your secondary color
//     },
//     error: {
//       main: red.A400,
//     },
//     background: {
//       default: '#303030',
//       paper: '#424242',
//     },
//     text: {
//       primary: '#ffffff',
//       secondary: '#aaaaaa',
//     },
//   },
//   typography: {
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//   },
//   shape: {
//     borderRadius: 8,
//   },
// });

// export default theme;
