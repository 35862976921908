import './styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import apiAdapter from 'Services/apiAdapter'
import swal from 'sweetalert'
import { STORAGE_KEYS } from 'Utils/Constants'
import { getLocal } from 'Utils/LocalStorageHandler'

const CreateRoleModal = ({
  isOpen,
  onClose,
  initialData = null,
  setLoader,
  onSuccess, // New prop to trigger success callback
}) => {
  const { user } = getLocal(STORAGE_KEYS.userDetails)

  const [formData, setFormData] = useState({
    userRole: initialData ? initialData.userRole : '',
    createdBy: initialData ? initialData.createdBy : user.userEmail,
    updatedBy: initialData ? user.userEmail : '',
    status: initialData ? initialData.status : 'Active',
    parentAssociation: initialData ? initialData.parentAssociation : false,
  })

  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (initialData) {
      setFormData({
        userRole: initialData.userRole,
        createdBy: initialData.createdBy,
        updatedBy: user.userEmail,
        status: initialData.status,
        parentAssociation: initialData.parentAssociation,
        userRoleId: initialData.userRoleId,
      })
    } else {
      setFormData({
        userRole: '',
        createdBy: user.userEmail,
        updatedBy: '',
        status: 'Active',
        parentAssociation: false,
      })
    }
  }, [initialData])

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target

    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value })

    // Clear errors on change
    setErrors({ ...errors, [name]: '' })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    let errorMsg = ''

    if (!value && name === 'userRole') {
      errorMsg = 'Required'
    }

    setErrors({ ...errors, [name]: errorMsg })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!formData.userRole) {
      setErrors({ userRole: 'Required' })

      return
    }
    setLoader(true)
    try {
      if (initialData) {
        await apiAdapter.updateUserRole(formData)
        swal('Success', 'User role updated successfully!', 'success')
      } else {
        await apiAdapter.createUserRole(formData)
        swal('Success', 'User role created successfully!', 'success')
      }

      onSuccess() // Trigger the parent callback to fetch updated data
    } catch (error) {
      console.error('Error occurred:', error)
      swal(
        'Error',
        'An error occurred while processing your request.',
        'error',
      )
    } finally {
      setLoader(false)
    }
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="modal-container">
        <div className="modal-header">
          <Typography variant="h6">
            {initialData ? 'Edit Role' : 'Create Role'}
          </Typography>
          <IconButton className="close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  className="input-field"
                  label="User Role"
                  name="userRole"
                  value={formData.userRole}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.userRole}
                  helperText={errors.userRole}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </div>
              {initialData && (
                <div className="form-group">
                  <TextField
                    className="input-field"
                    label="Updated By"
                    name="updatedBy"
                    value={formData.updatedBy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              {!initialData && (
                <div className="form-group">
                  <TextField
                    className="input-field"
                    label="Created By"
                    name="createdBy"
                    value={formData.createdBy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled
                  />
                </div>
              )}
              {initialData && (
                <div className="form-group">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.status}
                  >
                    <InputLabel id="status-dropdown">Status</InputLabel>
                    <Select
                      className="dropdown"
                      labelId="status-dropdown"
                      id="status-dropdown-select"
                      value={formData.status}
                      name="status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Status"
                    >
                      <MenuItem value="ACTIVE">Active</MenuItem>
                      <MenuItem value="INACTIVE">In-active</MenuItem>
                    </Select>
                    {errors.status && (
                      <FormHelperText>{errors.status}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              )}
            </div>
            <div className="form-group form-group-full">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.parentAssociation}
                    onChange={handleChange}
                    name="parentAssociation"
                    color="primary"
                  />
                }
                label="Parent Association"
              />
            </div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="save-button"
              type="submit"
              disabled={!formData.userRole || errors.userRole}
            >
              {initialData ? 'Update' : 'Save'}
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default Loader(CreateRoleModal)
